import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { LoopringAccountProvider } from 'src/contexts/account-context';
import { LoopringUnlockProvider } from 'src/contexts/unlock-context';

const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;
// const infuraId = process.env.REACT_APP_INFURA_PROJECT_ID;

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain === mainnet) {
          return {
            http: 'https://mainneteth.loopring.io',
          };
        }
        return {
          http: 'https://mainneteth.loopring.io',
        };
      },
    }),
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'MintStream',
  projectId,
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
  connectors,
});

export default function MyApp({ children }) {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <LoopringAccountProvider>
          <LoopringUnlockProvider>{children}</LoopringUnlockProvider>
        </LoopringAccountProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}
