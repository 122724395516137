import React from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

function ReleaseTypeTabs({ value, onChange }) {
  const handleChange = (event, newValue) => onChange(newValue);

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="Tabs example"
      centered
      variant="fullWidth"
    >
      <Tab label="Audio" value="audio" icon={<AudiotrackIcon />} />
      <Tab label="Video" value="video" icon={<VideoLibraryIcon />} />
    </Tabs>
  );
}

export default ReleaseTypeTabs;
