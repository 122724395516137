// @mui
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
// @web3-react
import useTokenResolver from 'src/hooks/useTokenResolver';
import { useLibrary } from 'src/contexts/library';
import { ConnectButton } from 'src/layouts/_common';

function TokenMenuItem({ nftId }) {
  const { loading, metadata } = useTokenResolver(nftId);
  const name = metadata?.name ?? nftId;
  return <p>{loading ? nftId : name ?? nftId}</p>;
}

export default function TokenSelect({ active, isLoading, rows, value, onChange, sx }) {
  const { address } = useLibrary();
  if (isLoading) return <div>Loading...</div>;

  const renderContent = (
    <FormControl sx={sx}>
      <InputLabel id="nft-select-label">Select NFT</InputLabel>
      <Select
        labelId="nft-select"
        id="nft-select"
        value={value}
        label="Select NFT"
        onChange={onChange}
      >
        {(rows ?? []).map(({ nftId }) => (
          <MenuItem key={nftId} value={nftId}>
            <TokenMenuItem nftId={nftId} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return address ? renderContent : <ConnectButton />;
}
