import { Helmet } from 'react-helmet-async';
// sections
import { CreateView } from 'src/sections/create/view';

// ----------------------------------------------------------------------

export default function OverviewAppPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Create</title>
      </Helmet>

      <CreateView />
    </>
  );
}
