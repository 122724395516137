import { useState, useEffect } from 'react';
import { db } from 'src/utils/firebase'; // Import your Firestore instance
// import _ from 'lodash';
// import { fetchArtists } from './ArtistService';
import { tracksCollection, TrackRef } from 'src/models';

// Function to create a new track
export const createTrack = async (trackData) => {
  try {
    // Create a new track document in Firestore
    const trackRef = await tracksCollection.add(trackData);

    return trackRef.id; // Return the track ID
  } catch (error) {
    console.error('Error creating track:', error);
    throw error;
  }
};

export const updateTrack = async (trackId, trackData) => {
  try {
    // Get the album document reference from Firestore
    const trackRef = TrackRef(trackId);
    // Append the new track references to the album's trackRefs array
    await trackRef.update(trackData, { merge: true });

    return trackRef.id; // Return the updated album ID
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const saveTrack = async (trackData) => {
  if (trackData.id) {
    return updateTrack(trackData.id, trackData);
  }
  return createTrack(trackData);
};

// Function to resolve track data
export const getTracks = async (trackRefs) => {
  if (!trackRefs || trackRefs.length === 0) {
    return []; // No track references provided, return an empty array
  }

  try {
    const resolvedTracks = await db.runTransaction(async (transaction) => {
      const tracks = [];

      // Gather all promises from the transaction operations
      const promises = trackRefs.map(async (trackRef) => {
        const trackDoc = TrackRef(trackRef.id);
        const doc = await transaction.get(trackDoc).catch(console.warn);

        if (doc.exists) {
          tracks.push({ id: doc.id, ...doc.data() });
        }
      });

      // Wait for all transaction operations to complete
      await Promise.all(promises);

      return tracks;
    });

    // Sort resolved tracks based on their position in trackRefs
    const sortedTracks = trackRefs.map((trackRef) =>
      resolvedTracks.find((track) => track.id === trackRef.id)
    );

    return sortedTracks;
  } catch (error) {
    console.error(error);
    return []; // Return an empty array in case of error
  }
};

export const useSong = (songId) => {
  const [song, setSong] = useState(null);
  const [album, setAlbum] = useState(null);
  const [artist, setArtist] = useState(null);

  useEffect(() => {
    const unsubscribe = db
      .collection('tracks')
      .doc(songId)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          const songData = snapshot.data();
          setSong(songData);

          // Resolve the album reference
          songData.albumRef.get().then((albumSnapshot) => {
            if (albumSnapshot.exists) {
              const albumData = albumSnapshot.data();
              setAlbum(albumData);
            }
          });

          // Resolve the artist reference
          songData.artistRef?.get().then((artistSnapshot) => {
            if (artistSnapshot.exists) {
              const artistData = artistSnapshot.data();
              setArtist(artistData);
            }
          });
        } else {
          setSong(null);
          setAlbum(null);
          setArtist(null);
        }
      });

    return () => unsubscribe();
  }, [songId]);

  return { song, album, artist };
};

export const useSongs = (library) => {
  const [songsWithAlbum, setSongsWithAlbum] = useState([]);

  useEffect(() => {
    // Fetch songs from Firestore
    const fetchSongs = async () => {
      try {
        const songCollection = await db.collection('tracks').get();
        const songs = songCollection.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch albums for each song
        const results = await Promise.all(
          songs.map(async (song) => {
            try {
              const albumSnapshot = await song.albumRef.get();

              if (albumSnapshot.exists) {
                const albumData = albumSnapshot.data();
                return { ...song, album: albumData };
              }
              return song;
            } catch (error) {
              console.error(error.message);
              return song;
            }
          })
        );

        setSongsWithAlbum(results);
      } catch (error) {
        console.error('Error fetching songs with albums:', error);
      }
    };

    const fetchSongsForLibrary = async () => {
      console.log(library);
      try {
        const trackRefs = library.map((album) => album?.trackRefs).flat();

        // Fetch albums for each song
        const results = await fetchSongs(trackRefs);
        const songsWithAlbums = results?.map((track) => {
          const album = library.find((e) => e.trackRefs.map((t) => t.id).includes(track.id));

          return {
            ...track,
            album,
          };
        });

        console.log(songsWithAlbums);
        setSongsWithAlbum(songsWithAlbums);
      } catch (error) {
        console.error('Error fetching songs with albums:', error);
      }
    };

    if (library) {
      fetchSongsForLibrary();
    } else {
      fetchSongs();
    }
  }, [library]);

  return songsWithAlbum;
};
