import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const PlaylistTypeSelect = ({ value, onChange = console.log }) => {
  const playlistTypes = ['playlist', 'album', 'ep', 'single', 'compilation'];
  const [selectedType, setSelectedType] = useState(value ? value.toLowerCase() : playlistTypes[1]);

  const handleTypeChanged = (event) => {
    const selectedValue = event.target.value;
    setSelectedType(selectedValue.toLowerCase()); // Convert to lowercase

    // Pass the selected value to the parent component
    onChange(selectedValue.toLowerCase()); // Convert to lowercase
  };

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor="playlist-type-select">Playlist Type</InputLabel>
      <Select
        size="small"
        label="Playlist Type"
        inputProps={{
          name: 'playlist',
          id: 'playlist-type-select',
        }}
        value={selectedType}
        onChange={handleTypeChanged}
      >
        {playlistTypes.map((type, index) => (
          <MenuItem key={index} value={type}>
            {type.charAt(0).toUpperCase() + type.slice(1)} {/* Capitalize the label */}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PlaylistTypeSelect;
