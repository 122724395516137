import React from 'react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

const apiKey = process.env.REACT_APP_BUG_SNAG_API_KEY;
Bugsnag.start({
  apiKey,
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey });

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const ErrorView = () => (
  <div>
    <p>uh oh... that was not supposed to happen</p>
  </div>
);

export function BugCatcher({ children }) {
  return <ErrorBoundary FallbackComponent={ErrorView}>{children}</ErrorBoundary>;
}
