import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';

import { FIREBASE_API } from 'src/config-global';
// Initialize Firebase
firebase.initializeApp(FIREBASE_API);

// Create and export the Firestore instance
export const db = firebase.firestore();
export const analytics = firebase.analytics();
// export const auth = firebase.auth();

// setup emus
if (process.env.REACT_APP_ENV === 'development') {
  console.log('configuring emus');
  db.useEmulator('127.0.0.1', 8080);
  // auth.useEmulator('127.0.0.1', 9099);
}

// Function to get the server timestamp
export const getTimestamp = () => firebase.firestore.FieldValue.serverTimestamp();
// export const firestore = firebase.firestore;
export const arrayUnion = (data) => firebase.firestore.FieldValue.arrayUnion(data);
export const arrayRemove = (data) => firebase.firestore.FieldValue.arrayRemove(data);
