import * as React from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { Stack, Typography, Grid, List } from '@mui/material';
import _ from 'lodash';

import { useLibrary } from 'src/contexts/library';
import { useArtist } from 'src/services/ArtistService';
import AlbumGrid from 'src/components/album-grid';
import SuggestConnect from 'src/components/SuggestConnect';
import EmptyContent from 'src/components/empty-content';
import { ArtistCard } from './ArtistCard';
import { ArtistRow } from './ArtistRow';

// ----------------------------------------------------------------------

export default function ArtistsView() {
  const navigate = useNavigate();
  const { address, artists } = useLibrary();
  const { id: selectedArtist } = useParams();

  const selectArtist = (artistId) => navigate(`/dashboard/artists/${artistId}`);

  if (!address) return <SuggestConnect title="My Artists | Connect to view" />;

  if (_.isEmpty(artists))
    return (
      <EmptyContent
        filled
        title="No Releases Found"
        description={
          <>
            Consider going shopping on <a href="https://loopexchange.art">LoopExchange</a> for some
            great music!
            <br />
            🛍️
          </>
        }
        sx={{ py: 10, m: 2 }}
      />
    );

  return (
    <Grid container spacing={1} justifyContent="space-between">
      <Grid item xs={12} sm={3}>
        <Stack>
          <ArtistList selection={selectedArtist} data={artists} onClick={selectArtist} />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={9}>
        <ArtistDetails data={artists} onClick={selectArtist} />
      </Grid>
    </Grid>
  );
}

function ArtistList({ selection, data, onClick }) {
  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {data?.map((e) => (
        <ArtistRow
          key={e.id}
          selected={e.id === selection}
          data={e}
          onClick={() => onClick(e.id)}
        />
      ))}
    </List>
  );
}

function ArtistDetails({ data, onClick }) {
  const { id } = useParams();
  const artist = useArtist(id);
  const { albums } = useLibrary();
  // const releases = useArtistReleases(id);
  const ownedReleases = albums.filter((e) => e.artist.id === id);

  return (
    <Stack>
      <Typography variant="h1">{artist?.name ?? 'All Artists'}</Typography>
      {artist ? <AlbumGrid data={ownedReleases} /> : <AllArtists onClick={onClick} />}
    </Stack>
  );
}

const AllArtists = ({ onClick }) => {
  const { artists } = useLibrary();

  return (
    <Grid container spacing={2}>
      {(artists ?? []).map((v) => (
        <Grid item xs={6} sm={4} lg={3}>
          <ArtistCard data={v} onClick={onClick} />
        </Grid>
      ))}
    </Grid>
  );
};
