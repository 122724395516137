import * as React from 'react';

import { IconButton, Avatar, Stack, Typography, Grid, List } from '@mui/material';

import { useLibrary } from 'src/contexts/library';
import { useMyArtists, useArtist } from 'src/services/ArtistService';
import AlbumGrid from 'src/components/album-grid';
import SuggestConnect from 'src/components/SuggestConnect';
import { ArtistRow } from 'src/sections/artists/view/ArtistRow';
import { ArtistCard } from 'src/sections/artists/view/ArtistCard';
import { resolveURL } from 'src/utils/ipfs';

// ----------------------------------------------------------------------

export default function ArtistsView() {
  const { address } = useLibrary();
  const artists = useMyArtists(address);

  const [selectedArtist, setSelectedArtist] = React.useState(null);

  const selectArtist = (artistId) => setSelectedArtist(artistId);

  const renderSuggest = () => <SuggestConnect title="My Artists | Connect to view" />;
  const renderContent = () => (
    <Grid container spacing={1} justifyContent="space-between">
      <Grid item xs={12} sm={3}>
        <Stack>
          <ArtistList selection={selectedArtist} data={artists} onClick={selectArtist} />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={9}>
        <ArtistDetails
          data={artists?.find((e) => e.id === selectedArtist) ?? artists}
          onClick={selectArtist}
        />
      </Grid>
    </Grid>
  );

  return address ? renderContent() : renderSuggest();
}

function ArtistList({ selection, data, onClick }) {
  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {data?.map((e) => (
        <ArtistRow
          key={e.id}
          selected={e.id === selection}
          data={e}
          onClick={() => onClick(e.id)}
        />
      ))}
    </List>
  );
}

function ArtistDetails({ allArtists, data, onClick }) {
  const artist = useArtist(data?.id);
  const { albums } = useLibrary();
  // const releases = useArtistReleases(id);
  const ownedReleases = albums.filter((e) => e.artist.id === data?.id);

  function handleNewProfilePic() {
    console.log('New profile picture');
  }

  return (
    <Stack alignItems="center">
      <IconButton onClick={() => handleNewProfilePic()}>
        <Avatar alt={data?.name} src={resolveURL(data?.thumbnail)} sx={{ width: 84, height: 84 }} />
      </IconButton>

      <Typography variant="h1">{artist?.name ?? 'All Artists'}</Typography>
      {artist ? (
        <AlbumGrid data={ownedReleases} />
      ) : (
        <AllArtists artists={allArtists} onClick={onClick} />
      )}
    </Stack>
  );
}

function AllArtists({ artists, onClick }) {
  return (
    <Grid container spacing={2}>
      {(artists ?? []).map((v) => (
        <Grid item xs={6} sm={4} lg={3}>
          <ArtistCard data={v} onClick={onClick} />
        </Grid>
      ))}
    </Grid>
  );
}
