// import { useState } from 'react';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import { alpha } from '@mui/material/styles';
import {
  Typography,
  Stack,
  IconButton,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  TextField,
} from '@mui/material';
import { DragHandle, Delete, Add } from '@mui/icons-material';
import { Container as DragContainer, Draggable } from 'react-smooth-dnd';

export default function SortableList({ rows, onChange, handleClick }) {
  const onDrop = (result) => {
    const { removedIndex, addedIndex } = result;
    const newTracks = arrayMove(rows, removedIndex, addedIndex).map((e, i) => {
      e.id = i;
      return e;
    });
    onChange(newTracks);
  };

  const onDelete = (id) => {
    const index = rows?.findIndex((e) => e?.id === id);
    const updatedItems = [...rows];
    updatedItems.splice(index, 1);
    onChange(updatedItems);
  };

  const onFileChange = (newFile) => {
    const newFiles = replaceObjectById(rows, newFile);
    if (newFiles) onChange(newFiles);
  };

  const replaceObjectById = (array, newObject) => {
    const newArray = array.map((item) => {
      if (item.id === newObject.id) {
        return newObject; // Replace the matching object with the new object
      }
      return item; // Keep the original object
    });

    return newArray;
  };

  return (
    <div>
      <List
        sx={{
          width: 1,
          borderRadius: 2,
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
          border: (theme) => `dashed 1px ${theme.palette.divider}`,
        }}
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{ borderRadius: '16px 16px 0px 0px' }}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              Tracks
              <IconButton
                aria-label="add tracks"
                color="primary"
                onClick={() => {
                  handleClick();
                }}
              >
                <Add />
              </IconButton>
            </Stack>
          </ListSubheader>
        }
      >
        <DragContainer dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
          {rows?.length > 0 ? (
            rows.map((file, index) => (
              <Draggable key={index}>
                <FileCard file={file} onDelete={onDelete} onChange={onFileChange} />
              </Draggable>
            ))
          ) : (
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Typography variant="caption" sx={{ p: 1 }}>
                Add tracks to get started
              </Typography>
            </Stack>
          )}
        </DragContainer>
      </List>
    </div>
  );
}

const FileCard = ({ file, onChange, onDelete }) => (
  <ListItem>
    <ListItemIcon className="drag-handle">
      <DragHandle />
    </ListItemIcon>
    <TextField
      value={file?.name}
      onChange={(e) => onChange({ ...file, name: e.target.value })}
      size="small"
      fullWidth
      sx={{ paddingRight: 2 }}
    />
    {/* <ListItemText primary={name} /> */}
    <ListItemSecondaryAction>
      <IconButton edge="start" aria-label="delete" onClick={() => onDelete(file?.id)}>
        <Delete />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
);
