import { Container, Typography } from '@mui/material';
import _ from 'lodash';

import { useLibrary } from 'src/contexts/library';
import VideoList from 'src/components/video-list';
import SuggestConnect from 'src/components/SuggestConnect';
import EmptyContent from 'src/components/empty-content';

export default function VideosView() {
  const { address, videos } = useLibrary();

  if (!address) <SuggestConnect title="My Videos | Connect to view" />;

  if (_.isEmpty(videos))
    return (
      <EmptyContent filled title="No videos were found" description=":(" sx={{ py: 10, m: 2 }} />
    );

  return (
    <Container>
      <Typography variant="h1">Videos</Typography>
      <VideoList data={videos} />
    </Container>
  );
}
