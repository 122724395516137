import { useState, useEffect } from 'react';

import { releasesCollection, tracksCollection } from 'src/models';

export const useExplore = () => {
  const [loading, setLoading] = useState(false);
  const [tracks, setTracks] = useState([]);
  const [releases, setReleases] = useState([]);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      await Promise.all([
        fetchTracks().then(setTracks),
        fetchReleases().then((data) => {
          console.log(data);
          setReleases(data?.filter((e) => e.releaseType !== 'video'));
          setVideos(data?.filter((e) => e.releaseType === 'video'));
        }),
      ]);
      setLoading(false);
    }

    fetch();
  }, []);

  return { loading, tracks, releases, videos };
};

// Fetch songs from Firestore
const fetchTracks = async () => {
  try {
    const songSnapshots = await tracksCollection.orderBy('createdAt', 'desc').get();
    const songs = songSnapshots.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // .filter((e) => e.releaseType !== 'video');

    return songs;
  } catch (error) {
    console.error('Error fetching songs with albums:', error);
    return [];
  }
};

// Fetch releases from Firestore
const fetchReleases = async () => {
  try {
    const releaseSnapshots = await releasesCollection.orderBy('createdAt', 'desc').get();
    const releases = releaseSnapshots.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // .filter((e) => e.releaseType !== 'video');

    return releases;
  } catch (error) {
    console.error('Error fetching songs with albums:', error);
    return [];
  }
};

// // Fetch releases from Firestore
// const fetchVideos = async () => {
//   try {
//     const releaseSnapshots = await videosCollection.orderBy('createdAt', 'desc').get();
//     const releases = releaseSnapshots.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));
//     // .filter((e) => e.releaseType !== 'video');

//     return releases;
//   } catch (error) {
//     console.error('Error fetching songs with albums:', error);
//     return [];
//   }
// };
