import React from 'react';
import { Grid } from '@mui/material';
import AlbumCard from 'src/components/album-card';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

export default function AlbumGrid({ data, onClick }) {
  const navigate = useNavigate();
  const onAlbumClick = (id) => navigate(`/dashboard/albums/${id}`);

  return (
    <Grid container spacing={2}>
      {(data ?? []).map((e) => (
        <Grid item key={e.id} xs={6} md={3} lg={2}>
          <AlbumCard data={e} onAlbumClick={onClick ?? onAlbumClick} />
        </Grid>
      ))}
    </Grid>
  );
}
