import { useEffect, useState } from 'react';
import { db, arrayUnion, arrayRemove, getTimestamp } from '../utils/firebase'; // Import your Firestore instance
import * as SongService from './SongService';

const usePlaylists = (address) => {
  const [playlists, setPlaylists] = useState([]);

  useEffect(() => {
    let unsubscribe;
    if (address) {
      // Set up a snapshot listener for the "playlists" collection
      unsubscribe = db
        .collection('playlists')
        .where('type', '==', 'playlist')
        .where('owner', '==', address)
        .onSnapshot((snapshot) => {
          const updatedPlaylists = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setPlaylists(updatedPlaylists);
        });
    }
    // Unsubscribe from the listener when the component unmounts
    return () => unsubscribe?.();
  }, [address]);

  return playlists;
};

const addSongToPlaylist = async (playlistId, songId) => {
  try {
    await db
      .collection('playlists')
      .doc(playlistId)
      .update({
        trackRefs: arrayUnion(db.doc(`tracks/${songId}`)),
      });
  } catch (error) {
    console.error('Error adding song to playlist:', error);
    throw error;
  }
};

const removeSongFromPlaylist = async (playlistId, songId) => {
  try {
    await db
      .collection('playlists')
      .doc(playlistId)
      .update({
        trackRefs: arrayRemove(db.doc(`tracks/${songId}`)),
      });
  } catch (error) {
    console.error('Error removing song from playlist:', error);
    throw error;
  }
};

const reorderPlaylist = async (playlistId, newOrder) => {
  try {
    await db.collection('playlists').doc(playlistId).update({
      trackRefs: newOrder,
    });
  } catch (error) {
    console.error('Error reordering playlist:', error);
    throw error;
  }
};

const createPlaylist = async (ownerAddress, playlistName) => {
  try {
    // Create a new playlist document in the "playlists" collection
    const playlistRef = await db.collection('playlists').add({
      owner: ownerAddress,
      name: playlistName,
      type: 'playlist',
      createdAt: getTimestamp(),
      // Add any other properties you want to include in the playlist document
    });

    // Return the ID of the newly created playlist
    return playlistRef.id;
  } catch (error) {
    // Handle any errors that occur during the creation process
    console.error('Error creating playlist:', error);
    throw error; // You can choose to handle errors as needed
  }
};

const getPlaylistQuery = (playlistId) => db.collection('playlists').doc(playlistId);
export const getPlaylist = async (playlistId) => {
  const playlistDoc = await getPlaylistQuery(playlistId).get();

  if (!playlistDoc.exists) return null;

  const playlistData = playlistDoc.data();
  const { trackRefs, ...playlistWithoutTracks } = playlistData;

  // Resolve tracks
  const tracks = await SongService.getTracks(trackRefs);

  return {
    ...playlistWithoutTracks,
    tracks,
  };
};

export { usePlaylists, addSongToPlaylist, removeSongFromPlaylist, reorderPlaylist, createPlaylist };
