// DataCacheContext.js
import React, { createContext, useContext, useState, useMemo } from 'react';

const DataCacheContext = createContext();

const DataCacheProvider = ({ children }) => {
  const [cache, setCache] = useState({});

  const memoizedValue = useMemo(() => {
    const setCachedData = (key, data) => setCache((prevCache) => ({ ...prevCache, [key]: data }));
    const getCachedData = (key) => cache[key];

    return { setCachedData, getCachedData };
  }, [cache]);

  return <DataCacheContext.Provider value={memoizedValue}>{children}</DataCacheContext.Provider>;
};

const useDataCache = () => {
  const context = useContext(DataCacheContext);
  if (!context) {
    throw new Error('useDataCache must be used within a DataCacheProvider');
  }
  return context;
};

export { DataCacheProvider, useDataCache };
