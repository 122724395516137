import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { Howl } from 'howler';
import { useAccount } from 'wagmi';
import { resolveURLForCid } from 'src/utils/ipfs';
import { useSnackbar } from 'src/components/snackbar';
import { db, analytics } from 'src/utils/firebase';

export default function usePlayer({ library }) {
  const { enqueueSnackbar } = useSnackbar();

  const { address } = useAccount();
  const [playlist, setPlaylist] = useState(null);
  const [track, setTrack] = useState(null);
  const [volume, setVolume] = useState(1.0);
  const [shuffle, setShuffle] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0.0);
  const [loading, setLoading] = useState(false);
  const [activePreviewLength, setActivePreviewLength] = useState(null);

  const soundRef = useRef(null);

  // Function to handle scrubbing
  const [wasPlaying, setWasPlaying] = useState(null);
  const handleScrub = (event, newProgress) => {
    if (soundRef.current) {
      // pause track on start
      if (wasPlaying === null) {
        const newWasPlaying = soundRef.current.playing();
        setWasPlaying(newWasPlaying ?? false);
        if (newWasPlaying) soundRef.current.pause();
      }

      // update the progress of the slider
      if (event === 'onChange') {
        setProgress(newProgress);
      }

      // apply the new time to the sound instance
      if (event === 'onEnd') {
        const seekTime = (progress / 100) * soundRef.current.duration();
        soundRef.current.seek(seekTime);
        if (wasPlaying) soundRef.current.play();
        setWasPlaying(null);
      }
    }
  };

  const trackIds = library?.map((a) => a.trackRefs?.map((ref) => ref.id)).flat();
  const isTrackOwned = (t) => {
    if (!library || library?.length === 0) return false;
    const result = trackIds.includes(t.id);
    return result;
  };

  // a track can be played if there are 0 access groups, or if its in the users library
  const canPlayTrack = (t) => {
    if (_.isEmpty(address)) {
      return false;
    }
    if (_.isEmpty(t.album.accessGroups)) {
      return true;
    }
    return isTrackOwned(t);
  };

  // const viewOnLoopExchange = (snackbarId) => console.log('Open on LE', snackbarId);

  const timeoutRef = useRef(null);
  const setNewTrack = (newTrack, playlistContext) => {
    // Immediately update the track and loading state
    setTrack(newTrack);
    setLoading(true);

    if (!playlist) setPlaylist(playlistContext);
    resolveURLForCid(newTrack.fileId)
      .then((src) => {
        if (soundRef.current) {
          soundRef.current.stop();
          soundRef.current.unload();
        }
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }

        const newHowl = new Howl({
          src,
          volume,
          autoplay: false,
          preload: true,
          html5: true,
          onend: () => {
            setPlaying(false);
            setProgress(100);
            onNext();
          },
          onerror: (error) => {
            console.error(error);
            setLoading(false); // Set loading to false even if there's an error
          },
          onplay: () => {
            setPlaying(true);
            setLoading(false); // Set loading to false when the track starts playing

            const owned = canPlayTrack(newTrack); // Check if the track is owned
            if (!owned) {
              enqueueSnackbar(`🔊 Playing Preview`, {
                variant: 'info',
                autoHideDuration: 3000,
                // anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
                // action: () => (
                //   <Button
                //     variant="outlined"
                //     color="info"
                //     onClick={() => viewOnLoopExchange(newTrack.album?.nftId)}
                //   >
                //     View on LoopExchange
                //   </Button>
                // ),
              });
            }

            const countAsPlayDuration = (soundRef?.current?.duration() ?? 30000) * 0.65 * 1000;
            const previewDuration = owned ? countAsPlayDuration : 30000; // Set to 30 seconds if not owned, otherwise full track

            setActivePreviewLength(previewDuration);
            if (!owned) {
              timeoutRef.current = setTimeout(() => {
                enqueueSnackbar(`Purchase to listen to the full track!`, {
                  variant: 'info',
                  autoHideDuration: 3000,
                  // action: () => (
                  //   <Button
                  //     variant="outlined"
                  //     color="info"
                  //     onClick={() => viewOnLoopExchange(newTrack.album?.nftId)}
                  //   >
                  //     View on LoopExchange
                  //   </Button>
                  // ),
                });
                soundRef.current?.stop();
              }, previewDuration);
            } else {
              timeoutRef.current = setTimeout(() => {
                // Add a new document to the "events" collection
                analytics.logEvent('track_played', {
                  trackId: newTrack.id,
                  user: address ?? 'anonymous',
                });
                db.collection('events').add({
                  timestamp: new Date(), // You can use any timestamp you prefer
                  songData: newTrack, // You can pass any relevant song data here
                  owner: address ?? 'anonymous',
                  type: 'play',
                });
              }, previewDuration);
            }
          },
        });

        newHowl.load();
        newHowl.play();

        soundRef.current = newHowl;
        setProgress(0);
      })
      .catch((error) => {
        console.warn('failed to resolve cid', error);
        setLoading(false); // Set loading to false if URL resolution fails
      });
  };

  // TRANSPORT CONTROLS
  const onNext = (newShuffle, newSongs) => {
    if (newShuffle) setShuffle(newShuffle);
    if (newSongs) setPlaylist(newSongs);

    const _tracks = newSongs ?? playlist;

    if (shuffle || newShuffle) {
      const randomIndex = Math.floor(Math.random() * _tracks.length);
      setNewTrack(_tracks[randomIndex]);
    } else {
      const currentIndex = _tracks.findIndex((item) => item === track);
      const nextIndex = (currentIndex + 1) % _tracks.length;
      setNewTrack(_tracks[nextIndex]);
    }
  };

  const onPrevious = () => {
    if (soundRef.current) {
      const currentTime = soundRef.current.seek();
      if (currentTime <= 2) {
        // Restart the current song from the beginning
        soundRef.current.seek(0);
        setProgress(0);
      } else if (shuffle) {
        const randomIndex = Math.floor(Math.random() * playlist.length);
        setNewTrack(playlist[randomIndex]);
      } else {
        const currentIndex = playlist.findIndex((item) => item === track);
        const prevIndex = (currentIndex - 1 + playlist.length) % playlist.length;
        setNewTrack(playlist[prevIndex]);
      }
    }
  };

  const togglePlayback = () => {
    if (soundRef.current) {
      if (playing) {
        soundRef.current.pause();
      } else {
        soundRef.current.play();
      }
    }
    setPlaying((prev) => !prev);
  };

  // SHUFFLE & VOLUME
  const toggleShuffle = () => {
    setShuffle((prev) => !prev);
  };

  useEffect(() => {
    if (soundRef.current) {
      soundRef.current.stop();
      soundRef.current.unload();
    }
  }, []);

  const progressTimerRef = useRef(null);

  // Function to calculate progress
  const calculateProgress = () => {
    if (soundRef.current) {
      const currentProgress =
        (activePreviewLength ?? soundRef.current.seek() ?? 0) / (soundRef.current.duration() ?? 1);
      setProgress(currentProgress * 100);
    }
  };

  // Use useEffect to start progress timer
  useEffect(() => {
    progressTimerRef.current = setInterval(calculateProgress, 1000); // Update progress every second

    return () => {
      // Cleanup on unmount
      if (progressTimerRef.current) {
        clearInterval(progressTimerRef.current);
      }
    };
    // eslint-disable-next-line
  }, []);

  const playSongs = (songs, { shuffle: newShuffle }) => {
    if (songs.length === 0) return;
    if (newShuffle) {
      onNext(newShuffle, songs);
    } else {
      setNewTrack(songs[0], songs);
    }
  };

  return {
    track,
    loading,
    setTrack: setNewTrack,
    playSongs,
    volume,
    setVolume,
    shuffle,
    playing,
    progress,
    setProgress,
    playlist,
    setPlaylist,
    onNext,
    onPrevious,
    toggleShuffle,
    togglePlayback,
    handleScrub,
  };
}
