import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const ReleaseDatePicker = ({ value, onChange = console.log }) => {
  const handleValueChanged = (newValue) => {
    // Pass the selected value to the parent component
    onChange(newValue);
  };

  return (
    <DesktopDatePicker
      label="Release Date"
      value={value}
      minDate={new Date('2017-01-01')}
      onChange={handleValueChanged}
      size="small"
      sx={{ margin: 0 }}
      slotProps={{
        textField: {
          fullWidth: true,
          margin: 'normal',
        },
      }}
    />
  );
};

export default ReleaseDatePicker;
