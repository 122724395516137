import { useState } from 'react';
import {
  Typography,
  Container,
  Stack,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useSettingsContext } from 'src/components/settings';
import { useAuthContext } from 'src/auth/hooks';
import useLoopring from 'src/hooks/useLoopring';
import * as PlaylistService from 'src/services/PlaylistService';
import SuggestConnect from 'src/components/SuggestConnect';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function NewPlaylistView() {
  const settings = useSettingsContext();
  const { authenticated } = useAuthContext();

  const navigate = useNavigate();
  const { address } = useLoopring();
  const [name, setName] = useState('');

  const onBack = () => console.log('back');
  const save = () => {
    PlaylistService.createPlaylist(address, name).then((id) =>
      navigate(`/dashboard/playlist/${id}`)
    );
  };

  const canProceed = name.length > 0;
  const renderSuggest = () => <SuggestConnect title="New Playlist | Connect to view" />;
  const renderContent = () => (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Typography variant="h1">New Playlist</Typography>
      <Card>
        <CardHeader name="Create a new playlist" />

        <CardContent>
          <Stack padding={1} spacing={2}>
            {/* {files.length === 0 ? renderAddFiles : renderFiles} */}
            <TextField
              label="Name"
              placeholder="Name of your playlist"
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Stack>
          <Stack direction="row" spacing={1} width="100%">
            <Button variant="outlined" fullWidth onClick={onBack}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={save}
              disabled={!canProceed}
            >
              Next
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );

  return authenticated ? renderContent() : renderSuggest();
}
