/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-key */
import { useState } from 'react';
import { Avatar, Badge, Button, Menu, MenuItem, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import PlugIcon from '@mui/icons-material/PowerSettingsNewRounded';
import PowerIcon from '@mui/icons-material/Power';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
// import LockIcon from '@mui/icons-material/Lock';
import Person from '@mui/icons-material/Person';

import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useDisconnect, useAccount, useWalletClient, useNetwork } from 'wagmi';
import { SiweMessage } from 'siwe';
import { useAuthContext } from 'src/auth/hooks';
import axios from 'src/utils/axios';

const StyledBadge = styled(Badge)(({ theme, connected }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: connected,
    color: connected,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

export default function ConnectPopover() {
  const { disconnect } = useDisconnect();
  const { loginWithEthereum, authenticated, logout } = useAuthContext();
  const navigate = useNavigate();

  // const { isUnlocked, unlock } = useUnlockContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleDisconnect = () => {
    logout();
    disconnect();
  };
  const handleProfile = () => {
    navigate(`/dashboard/user/account`);
    handleClose();
  };

  const wallet = useAccount();
  const { address: _address } = wallet;
  const address = _address; // ?.toLowerCase();

  const { data: networkData } = useNetwork();
  // const { signMessageAsync } = useSignMessage();

  const { data: walletClient } = useWalletClient();

  const messageForNonce = (chainId, nonce) => {
    const data = {
      domain: window.location.host,
      address,
      statement: 'Sign in to MintStream.',
      uri: window.location.origin,
      version: '1',
      chainId,
      nonce,
    };

    return new SiweMessage(data);
  };

  const handleSignIn = async () => {
    if (!address) return;
    const chainId = networkData?.chain?.id || 1;

    const { data: nonceData } = await axios.post('api/getNonceToSign', { address });

    try {
      const messageData = messageForNonce(chainId, nonceData.nonce);
      const message = messageData.prepareMessage();

      // const signature = await signMessageAsync({ message: message.prepareMessage() });
      const signature = await walletClient.signMessage({
        account: wallet,
        message,
      });

      const { data: verifyData } = await axios.post('api/verify', {
        message,
        signature,
        address,
      });

      await loginWithEthereum(verifyData.token);
    } catch (error) {
      console.log('Error signing login signature: ', error);
      handleDisconnect();
    }
  };

  return (
    <div>
      <ConnectButton.Custom>
        {({ account, chain, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
          const renderAvatar = (
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
              connected={authenticated ? '#44b700' : '#ff0000'}
            >
              <Avatar
                alt={account?.display ?? ''}
                src={account?.ensAvatar}
                style={{
                  width: 24,
                  height: 24,
                  borderRadius: '100%',
                }}
              />
            </StyledBadge>
          );

          // Note: If your app doesn't use authentication, you
          // can remove all 'authenticationStatus' checks
          const ready = mounted && authenticationStatus !== 'loading';
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === 'authenticated');
          return (
            <div
              {...(!ready && {
                'aria-hidden': true,
                style: {
                  opacity: 0,
                  pointerEvents: 'none',
                  userSelect: 'none',
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return (
                    <Button
                      endIcon={<PowerIcon />}
                      onClick={openConnectModal}
                      variant="contained"
                      color="primary"
                    >
                      Connect Wallet
                    </Button>
                  );
                }
                if (chain.unsupported) {
                  return (
                    <Button onClick={openChainModal} variant="contained" color="primary">
                      Wrong network
                    </Button>
                  );
                }

                if (!authenticated) {
                  return (
                    <Button
                      onClick={handleSignIn}
                      variant="contained"
                      color="primary"
                      startIcon={renderAvatar}
                      endIcon={<PowerIcon />}
                    >
                      Login as {account.displayName}
                    </Button>
                  );
                }
                return (
                  <div style={{ display: 'flex', gap: 12 }}>
                    <Button
                      onClick={handleClick}
                      variant="contained"
                      color="primary"
                      startIcon={renderAvatar}
                      endIcon={<PowerIcon />}
                    >
                      {account.displayName}
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      <MenuItem onClick={handleProfile}>
                        <Person fontSize="small" />
                        Profile
                      </MenuItem>
                      {/* <MenuItem onClick={logout}>
                        <LockIcon fontSize="small" />
                        Sign Out
                      </MenuItem> */}
                      <Divider />
                      <MenuItem onClick={handleDisconnect}>
                        <PlugIcon fontSize="small" />
                        Disconnect
                      </MenuItem>
                    </Menu>
                  </div>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
    </div>
  );
}
