import { db } from 'src/utils/firebase'; // Import your Firebase configuration

export const fetchRecents = async (nftIds) => {
  const results = await indexLibrary(nftIds);
  // console.log('Index library completed:', nftIds, results);
  return results;
};

// Function to fetch recents
export const indexLibrary = async (nftIds) => {
  try {
    // Divide nftIds into chunks of 10 or fewer elements (Firestore limit is 10 documents per request)
    const chunkSize = 30;
    const chunks = [];
    for (let i = 0; i < nftIds.length; i += chunkSize) {
      chunks.push(nftIds.slice(i, i + chunkSize));
    }

    const updatedPlaylists = [];

    // Create an array of promises to fetch playlists in parallel
    const fetchPromises = [];

    chunks.forEach((chunk) => {
      const chunkPromise = db
        .collection('releases')
        .where('accessGroups', 'array-contains-any', chunk)
        .orderBy('type')
        .orderBy('releaseDate')
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            const playlistData = {
              id: doc.id,
              ...doc.data(),
            };
            updatedPlaylists.push(playlistData);
          });
        });

      fetchPromises.push(chunkPromise);
    });

    await Promise.all(fetchPromises);

    return updatedPlaylists;
  } catch (error) {
    console.error('Error fetching recents:', error);
    throw error;
  }
};
