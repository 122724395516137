import { Container, Stack, Typography } from '@mui/material';
import ConnectButton from 'src/layouts/_common/connect-button';

export default function SuggestConnect({ title }) {
  const prompt = title ?? 'Connect your wallet to view this page';
  return (
    <Container sx={{ height: '100%' }}>
      <Stack alignItems="center" justifyContent="center" spacing={2}>
        <Typography variant="h3">{prompt}</Typography>
        <ConnectButton />
      </Stack>
    </Container>
  );
}
