import { useEffect, useState } from 'react';
import { db } from 'src/utils/firebase'; // Import your custom db module

export const uniqueArtists = (library) => {
  // Fetch albums for each song
  // Create a Set to store unique artist IDs
  const uniqueArtistIds = new Set();

  // Iterate through the library to populate the set
  library?.forEach((release) => {
    uniqueArtistIds.add(release.artistRef.id);
  });

  // Convert the Set back to an array of unique artist IDs
  const uniqueArtistIdsArray = Array.from(uniqueArtistIds);

  return uniqueArtistIdsArray;
};

// Modify the fetchArtists function to handle errors gracefully
export const fetchArtists = async (artistIds) => {
  console.log('Resolving (fetch ids) artists list...', artistIds);
  try {
    const batchedTransaction = await db.runTransaction(async (transaction) => {
      const artistPromises = artistIds.map(async (artistId) => {
        const artistRef = db.collection('artists').doc(artistId);
        const artistDoc = await transaction.get(artistRef);

        if (artistDoc.exists) {
          return { id: artistDoc.id, ...artistDoc.data() };
        }
        return null;
      });

      const results = await Promise.all(artistPromises);
      return results.filter((e) => e !== null);
    });

    return batchedTransaction;
  } catch (error) {
    console.error('Error fetching artists:', error);
    return [];
  }
};

const artistDocQuery = (artistId) => db.collection('artists').doc(artistId);
export const getArtists = async (artistRefs) => {
  console.log('Resolving artists list...');
  if (!artistRefs || artistRefs.length === 0) {
    return []; // No track references provided, return an empty array
  }

  try {
    const resolvedArtists = await db.runTransaction(async (transaction) => {
      const artists = [];

      // Gather all promises from the transaction operations
      const promises = artistRefs.map(async (artistRef) => {
        const artistDoc = artistDocQuery(artistRef.id);
        const doc = await transaction.get(artistDoc);

        if (doc.exists) {
          artists.push({ id: doc.id, ...doc.data() });
        }
      });

      // Wait for all transaction operations to complete
      await Promise.all(promises);

      return artists;
    });

    return resolvedArtists;
  } catch (error) {
    console.error(error);
    return []; // Return an empty array in case of error
  }
};

export const getArtist = async (artistRef) => {
  console.log('Resolving artist...');
  // Resolve artist
  let artist = null;
  if (artistRef) {
    const artistSnapshot = await artistRef.get();
    if (artistSnapshot.exists) {
      artist = { id: artistSnapshot.id, ...artistSnapshot.data() };
    }
  }

  return artist;
};

export const useMyArtists = (address) => {
  const [artists, setArtists] = useState([]);

  useEffect(() => {
    let unsubscribe;

    if (address) {
      console.log('Resolving my artists...');
      // Set up a subscription for real-time updates
      unsubscribe = db
        .collection('artists')
        .where('owner', '==', address)
        .onSnapshot((snapshot) => {
          const updatedArtists = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setArtists(updatedArtists);
        });
    }

    // Unsubscribe from the listener when the component unmounts
    return () => unsubscribe?.();
  }, [address]);

  return artists;
};

export const createArtist = async (artistData) => {
  try {
    const docRef = await db.collection('artists').add(artistData);
    return docRef.id;
  } catch (error) {
    console.error('Error creating artist:', error);
    throw error;
  }
};

const getArtistDiscography = (address) =>
  db.collection('releases').where('owner', '==', address).orderBy('type').orderBy('releaseDate');

export const useAccountReleases = (address) => {
  const [releases, setReleases] = useState([]);

  useEffect(() => {
    // Create a Firestore reference to the artist document

    let unsubscribe;
    if (address) {
      console.log('Get artist discog: ', address);
      unsubscribe = getArtistDiscography(address).onSnapshot((snapshot) => {
        const releasesData = [];

        snapshot.forEach((doc) => {
          if (doc.exists) {
            // Resolve any additional references here if needed
            releasesData.push({
              id: doc.id,
              ...doc.data(),
            });
          }
        });

        setReleases(releasesData);
      });
    }

    return () => unsubscribe?.();
  }, [address]);

  return releases;
};

export const useArtistReleases = (artistId) => {
  const [releases, setReleases] = useState([]);

  useEffect(() => {
    // Create a Firestore reference to the artist document
    if (!artistId) {
      setReleases([]);
      return () => {};
    }

    const unsubscribe = getArtistDiscography(artistId).onSnapshot((snapshot) => {
      const releasesData = [];

      snapshot.forEach((doc) => {
        if (doc.exists) {
          // Resolve any additional references here if needed
          releasesData.push({
            id: doc.id,
            ...doc.data(),
          });
        }
      });

      setReleases(releasesData);
    });

    return () => unsubscribe();
  }, [artistId]);

  return releases;
};

export const useArtist = (artistId) => {
  const [artist, setArtist] = useState(null);

  useEffect(() => {
    const unsubscribe = db
      .collection('artists')
      .doc(artistId)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.data();
          // Resolve any additional references here if needed
          setArtist(data);
        } else {
          setArtist(null);
        }
      });

    return () => unsubscribe();
  }, [artistId]);

  return artist;
};
