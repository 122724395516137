import { Helmet } from 'react-helmet-async';
// sections
import { AlbumEdit } from 'src/sections/albums/view';

// ----------------------------------------------------------------------

export default function AlbumEditPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Edit Album</title>
      </Helmet>

      <AlbumEdit />
    </>
  );
}
