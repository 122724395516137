import { useState } from 'react';

import { Stack, Container, Typography, Pagination } from '@mui/material';

import { useSettingsContext } from 'src/components/settings';
import { useExplore } from 'src/services/ExploreService';
import VideoList from 'src/components/video-list';
import TrackList from 'src/components/track-list';
import AlbumGrid from 'src/components/album-grid';
import ReleaseTypeTabs from 'src/sections/albums/album-form/release-type-tabs';

// ----------------------------------------------------------------------

export default function ExploreView() {
  const settings = useSettingsContext();
  const { releases, tracks, videos } = useExplore();

  // Pagination states and settings
  const [albumPage, setAlbumPage] = useState(1);
  const [trackPage, setTrackPage] = useState(1);
  const [videoPage, setVideoPage] = useState(1);
  const itemsPerPage = 10; // Adjust the number of items per page as needed

  // Handlers for changing pages
  const handleAlbumPageChange = (event, value) => {
    setAlbumPage(value);
  };

  const handleTrackPageChange = (event, value) => {
    setTrackPage(value);
  };

  const handleVideoPageChange = (event, value) => {
    setVideoPage(value);
  };

  // Slice data for the current page
  const paginatedVideos = videos.slice((videoPage - 1) * itemsPerPage, videoPage * itemsPerPage);
  const paginatedAlbums = releases.slice((albumPage - 1) * itemsPerPage, albumPage * itemsPerPage);
  const paginatedTracks = tracks.slice((trackPage - 1) * itemsPerPage, trackPage * itemsPerPage);

  const [releaseType, setReleaseType] = useState('audio');
  const audioRelease = releaseType === 'audio';
  const videoRelease = releaseType === 'video';

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Typography variant="h1">Explore</Typography>

      <Stack spacing={2}>
        <ReleaseTypeTabs value={releaseType} onChange={setReleaseType} />

        {videoRelease && (
          <>
            <VideoList data={paginatedVideos} />
            <Pagination
              count={Math.ceil(videos.length / itemsPerPage)}
              page={videoPage}
              onChange={handleVideoPageChange}
            />
          </>
        )}

        {audioRelease && (
          <>
            <AlbumGrid data={paginatedAlbums} />
            <Pagination
              count={Math.ceil(releases.length / itemsPerPage)}
              page={albumPage}
              onChange={handleAlbumPageChange}
            />

            <TrackList data={paginatedTracks} />
            <Pagination
              count={Math.ceil(tracks.length / itemsPerPage)}
              page={trackPage}
              onChange={handleTrackPageChange}
            />
          </>
        )}
      </Stack>
    </Container>
  );
}
