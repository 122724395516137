import { Helmet } from 'react-helmet-async';

import { WatchView } from 'src/sections/watch/view';

// sections

// ----------------------------------------------------------------------

export default function WatchPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Watch</title>
      </Helmet>

      <WatchView />
    </>
  );
}
