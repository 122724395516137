import Typography from '@mui/material/Typography';
import { Grid, Card, CardHeader, CardContent } from '@mui/material';
// components
import { createType } from './types';

const CreateMint = ({ setSelection }) => {
  const { name, description } = createType.find((e) => e.value === 'mint');

  return (
    <Grid container spacing={4} textAlign="start">
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="h2">
                {name}
              </Typography>
            }
            subheader={
              <Typography variant="p" component="h3">
                {name}
              </Typography>
            }
          />
          <CardContent>
            <Typography variant="body2" component="p">
              {description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CreateMint;
