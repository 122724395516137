import React from 'react';

import { Typography, CardMedia, CardActionArea, CardContent } from '@mui/material';
import Ratio from 'react-ratio';

import IPFSImage from 'src/components/ipfs-image';

export default function AlbumCard({ data, onClick }) {
  const { id, title, artist, thumbnail } = data; // Assuming you have an 'id' property in your data

  const handleClick = () => onClick(id);

  return (
    <CardActionArea onClick={handleClick}>
      <CardMedia title={title}>
        <Ratio ratio={16 / 9} sx={{ width: '100%' }}>
          <IPFSImage
            src={thumbnail}
            sx={{
              objectFit: 'cover',
              height: '100%',
              width: '100%',
            }}
          />
        </Ratio>
      </CardMedia>
      <CardContent>
        <Typography sx={{ mb: 1 }}>{title}</Typography>
        <Typography variant="body" color="text.secondary">
          {artist?.name}
        </Typography>
      </CardContent>
    </CardActionArea>
  );
}
