import { useEffect, useRef } from 'react';

import { useSpring, useMotionValue, useTransform, m } from 'framer-motion';

export default function Card3D({
  dampeningFactor = 1,
  exclusive = false,
  invert = false,
  sx,
  children,
}) {
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const cardX = useSpring(x, {});
  const cardY = useSpring(y, {});

  const containerRef = useRef(null); // Adding container ref

  const handleMouseMove = (event) => {
    if (exclusive) return;
    const containerRect = containerRef.current.getBoundingClientRect();
    const centerX = containerRect.left + containerRect.width / 2;
    const centerY = containerRect.top + containerRect.height / 2;

    const offsetX = event.clientX - centerX;
    const offsetY = event.clientY - centerY;

    // Calculate distance from the center using Pythagorean theorem
    const distance = Math.sqrt(offsetX ** 2 + offsetY ** 2);

    // Adjust sensitivity based on distance
    const sensitivity = 0.005 * distance;

    // Update motion values gradually based on sensitivity
    const newX = offsetX * sensitivity * dampeningFactor;
    const newY = offsetY * sensitivity * dampeningFactor;

    cardX.set(newX);
    cardY.set(newY);
  };

  const handleMouseLeave = (event) => {
    if (exclusive) return;
    cardX.set(0);
    cardY.set(0);
  };

  useEffect(() => {
    const handleGlobalMovement = (event) => {
      if (!exclusive || !containerRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const centerX = containerRect.left + containerRect.width / 2;
      const centerY = containerRect.top + containerRect.height / 2;

      const offsetX = event.clientX - centerX;
      const offsetY = event.clientY - centerY;

      let newX = offsetX;
      let newY = offsetY;

      // Calculate distance from the center using Pythagorean theorem
      const distance = Math.sqrt(offsetX ** 2 + offsetY ** 2);

      // Adjust sensitivity based on distance
      const sensitivity = distance * 0.001 * dampeningFactor;

      // Update motion values gradually based on sensitivity
      newX = offsetX * sensitivity;
      newY = offsetY * sensitivity;

      cardX.set(newX);
      cardY.set(newY);
    };

    document.addEventListener('mousemove', handleGlobalMovement);

    return () => {
      document.removeEventListener('mousemove', handleGlobalMovement);
    };
  }, [cardX, cardY, exclusive, dampeningFactor]);

  const inv = (val) => (invert ? -val : val);
  const invArr = (arr) => arr.map(inv);

  const rotateX = useTransform(cardY, invArr([300, -300]), [10, -10]);
  const rotateY = useTransform(cardX, invArr([300, -300]), [-10, 10]);
  const cardRotateX = useTransform(cardY, invArr([300, -300]), [10, -10]);
  const cardRotateY = useTransform(cardX, invArr([300, -300]), [-10, 10]);

  return (
    <m.div
      ref={containerRef} // Assigning container ref
      style={{
        perspective: 800,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <m.div
        style={{
          margin: 'auto',
          transformStyle: 'preserve-3d',
          perspective: 800,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          rotateX,
          rotateY,
          ...sx,
        }}
        transition={{ velocity: 0 }}
      >
        <m.div
          key="card"
          style={{
            boxShadow: '0px 0px 30px -7px rgba(0,0,0,0.45)',
            borderRadius: 10,
            transformStyle: 'preserve-3d',
            perspective: 800,
            cardRotateX,
            cardRotateY,
            ...sx,
          }}
          transition={{ velocity: 0 }}
        >
          {children}
        </m.div>
      </m.div>
    </m.div>
  );
}
