import * as React from 'react';
import styled from '@emotion/styled';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NestedMenuItem from './nested-menu-item';

export const Dropdown = React.forwardRef(
  (
    {
      menu,
      onClose,
      contextMenu,
      anchorReference,
      keepOpen: keepOpenGlobal,
      open: controlledIsOpen,
      onOpen: onControlledOpen,
      minWidth,
    },
    ref
  ) => {
    const [isInternalOpen, setInternalOpen] = React.useState(null);

    const isOpen = controlledIsOpen || isInternalOpen;

    let anchorRef = React.useRef(null);
    if (ref) {
      anchorRef = ref;
    }

    // const handleOpen = (event) => {
    //   event.stopPropagation();
    //   if (menu.length) {
    //     if (onControlledOpen) onControlledOpen(event.currentTarget);
    //     else setInternalOpen(event.currentTarget);
    //   }
    // };

    const handleClose = (event) => {
      event.stopPropagation();

      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      handleForceClose();
      onClose();
    };

    const handleForceClose = () => {
      if (onControlledOpen) {
        onControlledOpen(null);
      } else setInternalOpen(null);
    };

    const renderMenu = (menuItem, index) => {
      const { keepOpen: keepOpenLocal, ...props } = menuItem.props;

      let extraProps = {};
      if (props.menu) {
        extraProps = {
          parentMenuOpen: isOpen,
        };
      }

      return React.createElement(menuItem.type, {
        ...props,
        key: index,
        ...extraProps,
        onClick: (event) => {
          event.stopPropagation();

          if (!keepOpenGlobal && !keepOpenLocal) {
            handleClose(event);
          }

          if (menuItem.props.onClick) {
            menuItem.props.onClick(event);
          }
        },
        children: props.menu ? React.Children.map(props.menu, renderMenu) : props.children,
      });
    };

    return (
      <Menu
        anchorEl={isOpen}
        open={!!isOpen}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX } // Adjust the left position as needed
            : undefined
        }
      >
        {React.Children.map(menu, renderMenu)}
      </Menu>
    );
  }
);

export const DropdownMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between !important;

  & > svg {
    margin-left: 32px;
  }
`;

export const DropdownNestedMenuItem = styled(NestedMenuItem)`
  display: flex;
  justify-content: space-between !important;

  & > svg {
    margin-left: 32px;
  }
`;
