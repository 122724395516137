import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Typography, Container, Grid, Stack, Paper } from '@mui/material';

import { useSettingsContext } from 'src/components/settings';
import { useVideo } from 'src/services/ReleaseService';
import { resolveURL } from 'src/utils/ipfs';

// import { useLibrary } from 'src/contexts/library';

// @mui

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function WatchView() {
  const settings = useSettingsContext();
  const { id } = useParams();
  const release = useVideo(id) ?? {};
  const { title, artist } = release;
  const src = release?.videos?.at(0)?.fileId;

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      {/* <Typography variant="h1">Watch</Typography> */}
      <Grid container spacing={3}>
        {/* Main Video */}
        <Grid item xs={12}>
          <Paper elevation={3}>
            {/* Placeholder for main video */}
            <ReactPlayer
              width="100%"
              height="auto"
              maxHeight="500px"
              controls={true}
              url={resolveURL(src)}
            />
            {/* Video Details */}
            <Stack spacing={1} sx={{ p: 2 }}>
              <Typography variant="h6">{title ?? 'Video'}</Typography>
              <Typography variant="subtitle1">{artist?.name ?? 'Artist/Channel Name'}</Typography>
              {/* Add more video details here */}
            </Stack>
          </Paper>
        </Grid>

        {/* Other Videos */}
        {/* <Grid item xs={12} md={4}>
          <Typography variant="h6">Other Videos</Typography>
          <Stack spacing={2} sx={{ p: 2 }}>
            <Paper elevation={3}>
              <img src="other_video_1.jpg" alt="Other Video 1" width="100%" />
              <Typography variant="subtitle1">Other Video 1</Typography>
            </Paper>
            <Paper elevation={3}>
              <img src="other_video_2.jpg" alt="Other Video 2" width="100%" />
              <Typography variant="subtitle1">Other Video 2</Typography>
            </Paper>
          </Stack>
        </Grid> */}
      </Grid>
    </Container>
  );
}
