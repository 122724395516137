import { Helmet } from 'react-helmet-async';
// sections
import { ExploreView } from 'src/sections/explore/view';

// ----------------------------------------------------------------------

export default function OverviewAppPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Explore</title>
      </Helmet>

      <ExploreView />
    </>
  );
}
