import { useState, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Container from '@mui/material/Container';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import AccountReleases from '../account-releases';
import AccountArtists from './user-account-artists';

// @mui
// routes
// _mock
// components
//

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'releases',
    label: 'Releases',
    icon: <Iconify icon="mdi:album" width={24} />,
  },
  {
    value: 'artists',
    label: 'Artists',
    icon: <Iconify icon="mdi:person" width={24} />,
  },
  // {
  //   value: 'general',
  //   label: 'General',
  //   icon: <Iconify icon="solar:user-id-bold" width={24} />,
  // },
];

// ----------------------------------------------------------------------

export default function AccountView() {
  const settings = useSettingsContext();

  const [currentTab, setCurrentTab] = useState('releases');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {TABS.map((tab) => (
          <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
        ))}
      </Tabs>

      {currentTab === 'releases' && <AccountReleases />}
      {currentTab === 'artists' && <AccountArtists />}

      {/* {currentTab === 'general' && <AccountGeneral />} */}
    </Container>
  );
}
