import { getTimestamp } from 'src/utils/firebase';
import { logsCollection } from 'src/models';

export const error = async (data) => log({ ...data, type: 'error' });
export const warn = async (data) => log({ ...data, type: 'warning' });
export const info = async (data) => log({ ...data, type: 'info' });

// Function to create an album with tracks
export const log = async (data) => {
  try {
    // Create the album document in Firestore with a unique ID
    const logRef = logsCollection.doc(); // Create a Firestore reference without ID

    // Update the album with track IDs
    await logRef.set({ ...data, createdAt: getTimestamp() });
  } catch (err) {
    console.error('Error logging:', err);
  }
};
