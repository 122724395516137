import * as React from 'react';
import { Typography, CardActionArea, Card, CardMedia, CardContent } from '@mui/material';
import Ratio from 'react-ratio';
import IPFSImage from 'src/components/ipfs-image';

export const ArtistCard = ({ data, onClick }) => {
  const { id, name, thumbnail } = data;

  return (
    <Card elevation={0}>
      <CardActionArea onClick={() => onClick(id)}>
        <CardMedia title={name}>
          <Ratio ratio={1} sx={{ width: '100%' }}>
            <IPFSImage
              src={thumbnail}
              sx={{
                objectFit: 'cover',
                height: '100%',
                width: '100%',
              }}
            />
          </Ratio>
        </CardMedia>
        <CardContent>
          <Typography sx={{ mb: 1 }}> {name}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
