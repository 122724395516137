import React from 'react';

import { Typography, CardMedia, Card, CardActionArea, CardContent } from '@mui/material';
import Ratio from 'react-ratio';

import IPFSImage from 'src/components/ipfs-image';
import Card3D from '../Card3D';

export default function AlbumCard({ data, onAlbumClick }) {
  const { id, title, artist, thumbnail } = data; // Assuming you have an 'id' property in your data

  const handleClick = () => onAlbumClick(id);

  return (
    <Card3D elevation={0} invert dampeningFactor={2} sx={{ height: '100%', width: '100%' }}>
      <Card sx={{ height: '100%', width: '100%' }}>
        <CardActionArea onClick={handleClick} sx={{ height: '100%', width: '100%' }}>
          <CardMedia title={title}>
            <Ratio ratio={1} sx={{ width: '100%' }}>
              <IPFSImage
                src={thumbnail}
                sx={{
                  objectFit: 'cover',
                  height: '100%',
                  width: '100%',
                }}
              />
            </Ratio>
          </CardMedia>
          <CardContent sx={{ height: '100%', width: '100%' }}>
            <Typography sx={{ mb: 1 }}>{title}</Typography>
            <Typography variant="body" color="text.secondary">
              {artist?.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Card3D>
  );
}
