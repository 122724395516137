import { useState, useEffect } from 'react';
import {
  CircularProgress,
  Stack,
  // Tooltip,
  Autocomplete,
  Chip,
  TextField,
  Checkbox,
} from '@mui/material';
// import Select from 'react-select';
import useLoopring from 'src/hooks/useLoopring';

// Import the fetchTokenMetadata function
import {
  parseCollectionId,
  fetchTokenMetadata,
  fetchTokenCollectionMetadata,
} from 'src/hooks/useTokenResolver';

const TokenGateBuilder = ({ value = [], onChange }) => {
  const { loading, mints } = useLoopring();

  const [resolvedTokenMetadata, setResolvedTokenMetadata] = useState({});
  const [resolvedCollectionMetadata, setResolvedCollectionMetadata] = useState({});
  const [selectedTokens, setSelectedTokens] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [tokensFromCollection, setTokensFromCollection] = useState([]);

  useEffect(() => {
    const resolveMints = async () => {
      const tokenResults = {};
      const collectionResults = {};

      await Promise.all(
        mints.map(async (nft) => {
          const tokenMetadata = await fetchTokenMetadata(nft.nftId);

          if (tokenMetadata) {
            tokenResults[nft.nftId] = tokenMetadata;

            const collectionId = parseCollectionId(tokenMetadata?.collection_metadata);
            if (collectionId) {
              const collectionMetadata = await fetchTokenCollectionMetadata(tokenMetadata);
              collectionResults[collectionId] = collectionMetadata;
            }
          }
        })
      );

      // set the passed ids of the tokens as selected
      const initialTokenIds = Object.keys(tokenResults).filter((id) => value.includes(id));
      setSelectedTokens(
        initialTokenIds.map((id) => ({ value: id, label: tokenResults[id]?.name ?? id }))
      );

      // set the passed ids of the collections as selected
      const initialCollectionIds = Object.keys(collectionResults).filter((id) =>
        value.includes(id)
      );
      setSelectedCollections(
        initialCollectionIds.map((id) => ({ value: id, label: collectionResults[id]?.name ?? id }))
      );

      setResolvedTokenMetadata(tokenResults);
      setResolvedCollectionMetadata(collectionResults);
    };

    resolveMints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mints]);

  useEffect(() => {
    const selectedNFTs = Object.entries(resolvedTokenMetadata)
      // eslint-disable-next-line no-unused-vars
      .filter(([nftId, metadata]) => {
        if (metadata.collection_metadata) {
          const collectionId = parseCollectionId(metadata.collection_metadata);
          return collectionId && selectedCollections.map((e) => e.value).includes(collectionId);
        }
        return false;
      })
      .map(([nftId]) => nftId);

    setTokensFromCollection(selectedNFTs);
  }, [resolvedTokenMetadata, selectedCollections]);

  const derivedSelection = [
    ...new Set([...tokensFromCollection, ...(selectedTokens?.map((e) => e.value) ?? [])]),
  ]?.map((nftId) => {
    const metadata = resolvedTokenMetadata[nftId];
    return {
      value: nftId,
      label: metadata ? metadata.name : nftId,
    };
  });

  useEffect(() => {
    const prepareResponseForChange = () => {
      const gateIds = [selectedTokens, selectedCollections]
        .flat()
        .map((e) => e.value)
        .filter((id) => !tokensFromCollection.includes(id));

      // Check if the metadata has been loaded
      const isMetadataLoaded =
        Object.keys(resolvedTokenMetadata).length > 0 &&
        Object.keys(resolvedCollectionMetadata).length > 0;

      if (isMetadataLoaded) {
        onChange(gateIds);
      }
    };

    prepareResponseForChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollections, selectedTokens, tokensFromCollection]);

  return (
    <Stack spacing={2}>
      {/* <Tooltip
        title="Users holding any tokens within the selected collection(s) will have access to these files."
        placement="top-end"
        key="collection-tip"
      >
        <Typography variant="caption">Collections: </Typography>
      </Tooltip> */}
      <Autocomplete
        fullWidth
        multiple
        value={selectedCollections}
        options={Object.keys(resolvedCollectionMetadata).map((id) => ({
          value: id,
          label: resolvedCollectionMetadata[id]?.name ?? id,
        }))}
        loading={loading}
        isOptionEqualToValue={(a, b) => a.value === b.value}
        onChange={(event, newValue) => setSelectedCollections(newValue)}
        disableCloseOnSelect
        getOptionLabel={(option) => option.value}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Collections"
            placeholder="Select or Search Collection"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.value}>
            <Checkbox key={option.value} size="small" disableRipple checked={selected} />
            {option.label}
          </li>
        )}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option.label}
              label={option.label}
              size="small"
              disabled={selectedCollections.includes(option.value)}
            />
          ))
        }
      />
      {/* <Tooltip
        title="Users holding any of the selected tokens will have access to these files."
        placement="top-end"
        key="tokens-tip"
      >
        <Typography variant="caption">Tokens: </Typography>
      </Tooltip> */}
      {/* {console.log(tokensFromCollection, derivedSelection)} */}
      <Autocomplete
        fullWidth
        multiple
        disableCloseOnSelect
        value={derivedSelection}
        loading={loading}
        options={mints?.map((e) => ({
          value: e?.nftId,
          label: resolvedTokenMetadata[e?.nftId]?.name || e?.nftId,
        }))}
        onChange={(event, newValue) => setSelectedTokens(newValue)}
        isOptionEqualToValue={(a, b) => a.value === b.value}
        getOptionLabel={(option) => option.value}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tokens"
            placeholder="Select or Search Tokens"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.value}>
            <Checkbox key={option.value} size="small" disableRipple checked={selected} />
            {option.label}
          </li>
        )}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option.value ?? index}
              label={option.label}
              size="small"
              disabled={tokensFromCollection.includes(option.value)}
            />
          ))
        }
      />
    </Stack>
  );
};

export default TokenGateBuilder;

/* <Tooltip
        title="Users holding any tokens within the selected collection(s) will have access to these files."
        placement="top-end"
        key="collection-tip"
      >
        <Typography variant="caption">Collections: </Typography>
      </Tooltip> */

/* <Select
        closeMenuOnSelect={false}
        value={selectedCollections}
        isMulti
        options={Object.keys(resolvedCollectionMetadata).map((id) => ({
          value: id,
          label: resolvedCollectionMetadata[id]?.name ?? id,
        }))}
        onChange={setSelectedCollections}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      /> */
