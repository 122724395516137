import { useParams } from 'react-router-dom';
import { Grid, Container } from '@mui/material';

import { useSettingsContext } from 'src/components/settings';
import { useRelease } from 'src/services/ReleaseService';
import SuggestConnect from 'src/components/SuggestConnect';
import { useAuthContext } from 'src/auth/hooks';
import AlbumForm from '../album-form/form';

export default function AlbumDetailPage() {
  const { authenticated } = useAuthContext();
  const settings = useSettingsContext();
  const { id } = useParams();
  const data = useRelease(id);

  const renderSuggest = () => <SuggestConnect title="My Songs | Connect to view" />;
  const renderContent = () => (
    <Container maxWidth={settings.themeStretch ? false : 'xl'} margin={2}>
      <Grid container spacing={2} padding={2} alignItems="center">
        <Grid item xs={12}>
          <AlbumForm data={data} />
        </Grid>
      </Grid>
    </Container>
  );

  return authenticated ? renderContent() : renderSuggest();
}
