import { useState, useEffect } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Backdrop,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import * as ArtistService from 'src/services/ArtistService';
import { useLibrary } from 'src/contexts/library';

function MyArtistSelect({ value, onChange }) {
  const { address } = useLibrary();
  const artists = ArtistService.useMyArtists(address);

  const [loading, setLoading] = useState(false);
  const [showArtistDialog, setShowArtistDialog] = useState(false);
  const [newArtistName, setNewArtistName] = useState('');
  const [selectedArtist, setSelectedArtist] = useState(null);

  useEffect(() => {
    if (value) {
      setSelectedArtist(value);
    } else if (artists.length > 0) {
      setSelectedArtist(artists[0].id);
    }
  }, [value, artists]);

  useEffect(() => {
    if (!selectedArtist && artists.length > 0) {
      setSelectedArtist(artists[0].id);
    }
  }, [artists, selectedArtist]);

  useEffect(() => {
    if (selectedArtist) {
      onChange(selectedArtist);
    }
  }, [onChange, selectedArtist]);

  const handleSelectChange = (event) => {
    const v = event.target.value;
    setSelectedArtist(v);

    if (v === 'create') {
      setShowArtistDialog(true);
    } else {
      onChange(v);
    }
  };

  const handleCreateNewArtist = () => {
    const fetch = async () => {
      setLoading(true);

      const id = await ArtistService.createArtist({ owner: address, name: newArtistName });

      setLoading(false);

      setSelectedArtist(id);
      setNewArtistName('');
      setShowArtistDialog(false);
      onChange(id); // Invoke onChange with the newly created artist id
    };

    fetch();
  };

  return (
    <>
      <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress />
      </Backdrop>

      <FormControl fullWidth>
        <InputLabel id="artist-select-label" size="small" shrink={selectedArtist !== null}>
          Select Artist
        </InputLabel>
        <Select
          labelId="artist-select-label"
          id="artist-select"
          label="Artist Select"
          value={selectedArtist}
          onChange={handleSelectChange}
          size="small"
        >
          {artists.map((artist) => (
            <MenuItem key={artist.id} value={artist.id}>
              {artist.name}
            </MenuItem>
          ))}
          <MenuItem key="create" value="create">
            Create Artist
          </MenuItem>
        </Select>
      </FormControl>

      <Dialog open={showArtistDialog} onClose={() => setShowArtistDialog(false)}>
        <DialogTitle>Create New Artist</DialogTitle>
        <DialogContent>
          <TextField
            label="Artist Name"
            value={newArtistName}
            onChange={(e) => setNewArtistName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowArtistDialog(false)} color="primary" variant="outline">
            Cancel
          </Button>
          <Button onClick={handleCreateNewArtist} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default MyArtistSelect;
