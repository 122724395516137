import { useState, useEffect } from 'react';

import _ from 'lodash';
import axios from 'axios';
import { Buffer } from 'buffer';
import * as sdk from '@loopring-web/loopring-sdk';
import { RateLimit } from 'async-sema';
// import { proxyUrl } from '../api/loopring';

export const IPFS_GATEWAYS = [
  'https://ipfs.loopring.io/ipfs/',
  'https://www.gstop-content.com/ipfs/',
  'https://nfttoolkit.infura-ipfs.io/ipfs/',
];
const IPFS_GATEWAY = IPFS_GATEWAYS[0];

const CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID) || 1;

const limit = RateLimit(4); // rps

// env vars
const {
  REACT_APP_INFURA_IPFS_API_KEY: INFURA_IPFS_API_KEY,
  REACT_APP_INFURA_IPFS_API_SECRET: INFURA_IPFS_API_SECRET,
} = process.env;

// Basic auth for headers sent to Infura IPFS API
const auth = `Basic ${Buffer.from(`${INFURA_IPFS_API_KEY}:${INFURA_IPFS_API_SECRET}`).toString(
  'base64'
)}`;

export async function fetchDAGForCID(cid) {
  await limit();
  // url containing the cid
  const url = `https://ipfs.infura.io:5001/api/v0/dag/get?arg=${cid}&encoding=json`;

  // Post request to the infura ipfs API
  const headers = {
    'Content-Type': 'application/json',
    Authorization: auth,
    RequestMode: 'no-cors',
  };
  const request = fetch(url, { method: 'POST', headers, redirect: 'follow' });
  const response = await request.then((res) => res.json()).catch(console.error);
  return parseLinks(response);
}

export const resolveURL = (cid) => fromCDN(`${IPFS_GATEWAY}${sanitizeCid(cid)}`);

export const fromCDN = (src, options = { h: 350 }) => {
  if (!src) throw new Error('A source must be provided in order to fetch from the CDN.');

  // URL encode the options object
  const encodedOptions = Object.entries(options)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  return `https://mintstream.art/.netlify/images?url=${encodeURIComponent(src)}&${encodedOptions}`;
};

let cancel; // Global variable to hold the cancel function
const cache = {}; // In-memory cache

export const resolveURLForCid = (cid) => {
  if (_.isEmpty(cid)) return Promise.reject(new Error('No CID was provided'));
  const sanitizedCid = sanitizeCid(cid);

  // Check if the result for this CID is already cached
  if (cache[sanitizedCid]) {
    return Promise.resolve(cache[sanitizedCid]);
  }

  // Recursive function to try each gateway
  const tryGateway = (index) => {
    if (cancel) {
      cancel('Cancelling previous request.');
    }

    if (index >= IPFS_GATEWAYS.length) {
      return Promise.reject(new Error('Failed to find file in all gateways.'));
    }

    const gateway = IPFS_GATEWAYS[index];
    const src = fromCDN(gateway + sanitizedCid);
    return axios
      .head(src, {
        timeout: 5000,
        cancelToken: new axios.CancelToken((c) => {
          // Assign the cancel function to the global variable
          cancel = c;
        }),
      })
      .then((response) => {
        if (response.status === 200) {
          const resolvedURL = src;
          // Cache the result
          cache[sanitizedCid] = resolvedURL;
          return resolvedURL;
        }
        return tryGateway(index + 1); // try the next gateway
      })
      .catch((error) => {
        console.warn('gateway failed: ', gateway, cid);
        return tryGateway(index + 1); // try the next gateway
      });
  };

  return tryGateway(0); // Start with the first gateway
};

const parseLinks = (data) => {
  if (!data || data?.Data == null) return null;
  // verify the document is using a file-structure encoding (instead of base64 or something)
  if (data?.Data['/']?.bytes === 'CAE') {
    return Object.keys(data.Links ?? {}).map((key) => {
      const link = data.Links[key];
      return { id: link.Hash['/'], name: link.Name, size: link.Tsize };
    });
  }
  return false;
};

export const nftAPI = new sdk.NFTAPI({ chainId: CHAIN_ID });

export const sanitizeCid = (cid = '') => cid?.replace('ipfs://', '');
export const Cid = (cid = '') => `ipfs://${sanitizeCid(cid)}`;

export const nftIdToCid = (nftId) => {
  if (!nftId || nftId?.length === 0) return '';
  return nftAPI.ipfsNftIDToCid(nftId);
};

// attempt to fetch JSON text from a CID
export async function fetchIPFS(cid) {
  if (_.isEmpty(cid)) return null;
  const ipfsSrc = IPFS_GATEWAY + cid;
  try {
    const data = await fetch(ipfsSrc)
      .then((res) => res.json())
      .catch(console.error);

    return data;
  } catch (error) {
    console.error('Error:', error);
    // Handle the error
    return error;
  }
}

// Now, 'resultTree' contains the entire file tree structure.

export function useIPFSFileTree(cid, contentTypeFilter = 'image') {
  const [loading] = useState(false);
  const [fileTree, setFileTree] = useState(null);
  const [singleFile, setSingleFile] = useState(null);

  useEffect(() => {
    const processDAG = async (_cid) => {
      try {
        const response = await fetchDAGForCID(_cid);

        const filteredFiles =
          response?.filter((file) => {
            const contentType = contentTypeForExtensions(extForFile(file));
            return contentType === contentTypeFilter;
          }) ?? [];

        setFileTree((prevFileTree) => {
          if (!prevFileTree) {
            return filteredFiles;
          }
          return prevFileTree.concat(filteredFiles);
        });

        const folderPromises = response.filter(isFolder).map((file) => processDAG(file.id));
        await Promise.allSettled(folderPromises);

        return filteredFiles;
      } catch (error) {
        console.error('Failed to fetch DAG', error);
        return [];
      }
    };

    if (!cid) return;
    setSingleFile(null);
    setFileTree(null);

    fetchHeadersForCID(cid).then(({ contentType, contentSize }) => {
      const type = contentTypeForExtensions(extForMime(contentType));
      if (type === 'video') setSingleFile({ id: cid, contentType: type, size: contentSize });
      else if (type === 'html') processDAG(cid);
      else console.log('No DAG available for CID');
    });
  }, [cid, contentTypeFilter]);

  return { loading, fileTree, singleFile };
}

export async function fetchHeadersForCID(cid) {
  await limit();

  const url = resolveURL(cid);
  const request = fetch(url, { method: 'HEAD', redirect: 'follow' });
  const headers = await request.then((res) => res.headers).catch(console.error);

  const contentType = headers.get('content-type');
  const contentSize = headers.get('content-size');

  return { contentType, contentSize };
}

const isFolder = (f) => extForFile(f) == null;

const extForFile = (file) => {
  const re = /(?:\.([^.]+))?$/;
  const ext = re.exec(file?.name)[1];
  return ext;
};

const extForMime = (mime) => {
  const subtypeMatch = mime?.match(/[^/]+$/);
  return subtypeMatch?.[0];
};

const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
const audioTypes = ['mp3', 'aif', 'aiff', 'wav', 'ogg'];
const videoTypes = ['mp4', 'quicktime', 'x-m4v'];
const interactiveTypes = ['html'];

const contentTypeForExtensions = (ext) => {
  ext = ext?.toLowerCase();
  if (imageTypes.includes(ext)) return 'image';
  if (audioTypes.includes(ext)) return 'audio';
  if (videoTypes.includes(ext)) return 'video';
  if (interactiveTypes.includes(ext)) return 'html';
  return null;
};
