import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';
import ArrowRight from '@mui/icons-material/ArrowRight';

import { useLibrary } from 'src/contexts/library';
import { useSnackbar } from 'src/components/snackbar';
import * as PlaylistService from 'src/services/PlaylistService';

import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from './dropdown';

function TrackContextMenu({ track, playlist: playlistContext, open, onClose, contextMenu }) {
  const navigate = useNavigate();
  const { playlists } = useLibrary();
  const { enqueueSnackbar } = useSnackbar();

  const onEvent = (event, value) => {
    switch (event) {
      case 'artist':
        return navigate(`/dashboard/artists/${track.artistRef.id}`);

      case 'album':
        return navigate(`/dashboard/albums/${track.albumRef.id}`);

      case 'playlist':
        return PlaylistService.addSongToPlaylist(value.id, track.id)
          .then(() =>
            enqueueSnackbar(`Added to playlist ${value.name}`, {
              variant: 'success',
            })
          )
          .catch((error) =>
            enqueueSnackbar(`Error adding to playlist: ${error.message}`, {
              variant: 'error',
            })
          );

      case 'removeFromPlaylist':
        return PlaylistService.removeSongFromPlaylist(playlistContext?.id, track.id)
          .then(() =>
            enqueueSnackbar(`Removed song: ${track?.name}`, {
              variant: 'success',
            })
          )
          .catch((error) =>
            enqueueSnackbar(`Error removing from playlist: ${error.message}`, {
              variant: 'error',
            })
          );

      default:
        return console.log('Handle event: ', event);
    }
  };

  const renderPlaylistContext = playlistContext
    ? [
        <Divider />,
        <DropdownMenuItem onClick={() => onEvent('removeFromPlaylist')}>
          Remove from Playlist
        </DropdownMenuItem>,
      ]
    : [];

  return (
    <Dropdown
      open={open}
      onClose={onClose}
      contextMenu={contextMenu}
      menu={[
        <DropdownNestedMenuItem
          label="Add to playlist"
          rightIcon={<ArrowRight />}
          menu={[
            <DropdownMenuItem onClick={() => onEvent('newPlaylist')}>
              New Playlist
            </DropdownMenuItem>,
            <Divider />,
            ...playlists.map((playlist) => (
              <DropdownMenuItem onClick={() => onEvent('playlist', playlist)}>
                {playlist?.name}
              </DropdownMenuItem>
            )),
          ]}
        />,
        ...renderPlaylistContext,
        <Divider />,
        <DropdownMenuItem onClick={() => onEvent('artist')}>Go to artist</DropdownMenuItem>,
        <DropdownMenuItem onClick={() => onEvent('album')}>Go to album</DropdownMenuItem>,
      ]}
    />
  );
}

export default TrackContextMenu;
