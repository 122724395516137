import { useEffect } from 'react';
import { List, Button } from '@mui/material';
import ReactPlayer from 'react-player';
import IPFSImage from 'src/components/ipfs-image';
import { resolveURL, useIPFSFileTree } from 'src/utils/ipfs';
import { IPFSFile } from './IPFSFileRow';

const IPFSFilePicker = ({
  limit,
  selection,
  cid,
  imageCid,
  contentType = 'audio',
  onFileClick,
  onAddAll,
}) => {
  const { loading, fileTree, singleFile } = useIPFSFileTree(cid, contentType);

  const isSelected = (file) => selection.find((e) => e === file.id);
  const addAll = () => onAddAll(fileTree);
  const deselectAll = () => onAddAll([]);

  const containsFiles = fileTree?.length > 0;
  const canAddMany = (!limit || limit <= 0) && containsFiles;
  const canDeselectAll = selection?.length === fileTree?.length;

  const mimeTypesAreCompatible =
    singleFile?.contentType === contentType ||
    ((singleFile?.contentType === 'audio' || singleFile?.contentType === 'video') &&
      contentType === 'audio');

  useEffect(() => {
    if (mimeTypesAreCompatible) {
      onAddAll([singleFile]);
    } else if (contentType === 'image') {
      onAddAll([{ id: imageCid, name: 'image' }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleFile]);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : !containsFiles ? (
        singleFile?.contentType === 'video' && contentType !== 'image' ? (
          <ReactPlayer width="100%" height="auto" controls={true} url={resolveURL(cid)} />
        ) : contentType === 'image' ? (
          <IPFSImage
            src={imageCid}
            sx={{
              width: '100%',
              height: 'auto',
            }}
          />
        ) : (
          <p>No {contentType} files found.</p>
        )
      ) : (
        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
            '& ul': { padding: 0 },
          }}
        >
          {(fileTree ?? []).map((file) => (
            <IPFSFile
              isSelected={isSelected}
              key={file?.name}
              file={file}
              onFileClick={() => (canAddMany ? onFileClick(file) : onAddAll([file]))}
            />
          ))}
        </List>
      )}
      {canAddMany && (
        <Button
          color="info"
          fullWidth
          disabled={!fileTree || fileTree?.length === 0}
          onClick={() => (canDeselectAll ? deselectAll() : addAll())}
        >
          {canDeselectAll ? 'Deselect All' : 'Select All'}
        </Button>
      )}
    </div>
  );
};

export default IPFSFilePicker;
