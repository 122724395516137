import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';

import _ from 'lodash';

import useLoopring from 'src/hooks/useLoopring';
import * as LibraryService from 'src/services/LibraryService';
import * as PlaylistService from 'src/services/PlaylistService';
import usePlayer from 'src/hooks/usePlayer';
import { useTokenGates } from 'src/components/useTokenGates';

// Create the LibraryContext
const LibraryContext = createContext();

// Create the LibraryProvider component
const LibraryProvider = ({ children }) => {
  const { address, nfts } = useLoopring();

  const nftIds = useMemo(() => nfts.map((e) => e.nftId), [nfts]); // Memoize nftIds
  const { loading, collections } = useTokenGates(nftIds);

  // recent releases owned by the current address
  const [albums, setAlbums] = useState([]);
  const [videos, setVideos] = useState([]);
  const tracks = albums.flatMap((album) => album.tracks.map((t) => ({ ...t, album })));
  const artists = _(albums).flatMap('artist').uniqBy('id').value();

  useEffect(() => {
    if (_.isEmpty(nftIds)) return;
    const accessGroups = [...nftIds, ...Object.keys(collections)];
    LibraryService.fetchRecents(accessGroups)
      .then((data) => {
        setAlbums(data.filter((e) => e.releaseType !== 'video'));
        setVideos(data.filter((e) => e.releaseType === 'video'));
      })
      .catch(console.warn)
      .finally(() => {
        console.log('Caching', albums);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftIds, collections]);

  const playlists = PlaylistService.usePlaylists(address);
  const player = usePlayer({ library: albums });

  const library = useMemo(
    () => ({
      artists,
      albums,
      playlists,
      tracks,
      player,
      address,
      loading,
      videos,
    }),
    [albums, artists, playlists, tracks, videos, player, address, loading]
  );

  return <LibraryContext.Provider value={library}>{children}</LibraryContext.Provider>;
};

// Create a custom hook for using the library context
const useLibrary = () => useContext(LibraryContext);

export { LibraryProvider, useLibrary };
