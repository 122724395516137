import { Suspense } from 'react';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Web3 from 'src/components/web3';
import App from './App';
import { BugCatcher } from './bugcatcher';

//

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BugCatcher>
    <HelmetProvider>
      <BrowserRouter>
        <Suspense>
          <Web3>
            <App />
          </Web3>
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  </BugCatcher>
);
