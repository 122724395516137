import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useLibrary } from 'src/contexts/library';
import AlbumGrid from 'src/components/album-grid';
import { useAccountReleases } from 'src/services/ArtistService';
import SuggestConnect from 'src/components/SuggestConnect';

// @mui
// import {use} from 'src/hooks/library';

//
// import AccountBillingPlan from './account-billing-plan';
// import AccountBillingPayment from './account-billing-payment';
// import AccountBillingHistory from './account-billing-history';
// import AccountBillingAddress from './account-billing-address';

// ----------------------------------------------------------------------

export default function AccountReleases({ cards, plans, invoices, addressBook }) {
  // const { albums } = useLibrary();
  const { address } = useLibrary();
  const releases = useAccountReleases(address);

  const navigate = useNavigate();
  const onAlbumClick = (id) => navigate(`/dashboard/albums/${id}/edit`);

  const renderSuggest = () => <SuggestConnect title="My Albums | Connect to view" />;
  const renderContent = () => (
    <>
      <Typography variant="h1">Albums</Typography>
      <AlbumGrid data={releases} onClick={onAlbumClick} />
    </>
  );

  return address ? renderContent() : renderSuggest();
}

AccountReleases.propTypes = {
  addressBook: PropTypes.array,
  cards: PropTypes.array,
  invoices: PropTypes.array,
  plans: PropTypes.array,
};
