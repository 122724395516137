export function fileNameToTitle(fileName) {
  // Split the file name by underscores, periods, and spaces and remove the file extension
  // const parts = fileName.split(/[_\s.]+/).map((part) => part.split('.')[0]);

  // // Capitalize the first letter of each word and join them with spaces
  // const title = parts
  //   .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
  //   .join(' ');

  return fileName;
}
