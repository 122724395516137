import { useParams } from 'react-router-dom';
import { Box, Grid, Stack, Container, Typography, Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ShuffleIcon from '@mui/icons-material/Shuffle';

import { useSettingsContext } from 'src/components/settings';
import { useLibrary } from 'src/contexts/library';
import { useRelease } from 'src/services/ReleaseService';

import IPFSImage from 'src/components/ipfs-image';
import TrackList from 'src/components/track-list';
import Ratio from 'react-ratio';

export default function AlbumDetailPage() {
  const settings = useSettingsContext();
  // const navigate = useNavigate();
  const { id } = useParams();
  const release = useRelease(id) ?? {};
  const { title, tracks, artist, thumbnail } = release;
  console.log(release);
  const { player } = useLibrary();

  const textStyle = {
    textShadow: '1px 1px 2px black',
    mixBlendMode: 'screen',
    color: 'white',
  };

  const handlePlay = (props) => player.playSongs(tracks, props);
  // const handleArtist = () => navigate(`/dashboard/artists/${id}`);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'} margin={2}>
      <Grid container spacing={2} padding={2} alignItems="center">
        {/* Fill the header space with content */}
        <Grid item xs={12}>
          <Box
            sx={{
              position: 'relative',
              maxWidth: '100%',
              margin: '0 auto',
              overflow: 'hidden',
              height: 300,
              borderRadius: '16px',
              paddingBottom: 2,
            }}
          >
            <IPFSImage
              src={thumbnail}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                zIndex: -2,
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: -1,
                backdropFilter: `blur(12px)`,
              }}
            />
            <Stack
              direction="row"
              sx={{ height: '100%' }}
              alignItems="center"
              justifyContent="center"
            >
              <Grid container spacing={2} padding={2} alignItems="center">
                <Grid item xs={3}>
                  <Ratio ratio={1} sx={{ width: '100%' }}>
                    <IPFSImage
                      src={thumbnail}
                      sx={{
                        borderRadius: '8px',
                        objectFit: 'cover',
                        height: '100%',
                        width: '100%',
                      }}
                    />
                  </Ratio>
                </Grid>
                <Grid item xs={9}>
                  <Stack alignItems="start" justifyContent="center" sx={{ height: '100%' }}>
                    <Typography
                      variant="h2"
                      component="h1"
                      sx={{
                        ...textStyle,
                      }}
                    >
                      {title}
                    </Typography>
                    <Typography
                      variant="h3"
                      component="h2"
                      sx={{
                        fontWeight: 'light',
                        ...textStyle,
                      }}
                    >
                      {artist?.name}
                    </Typography>
                    <Stack direction="row" alignItems="start" width="100%" spacing={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PlayArrowIcon />}
                        onClick={() => handlePlay({ shuffle: false })}
                      >
                        Play
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ShuffleIcon />}
                        onClick={() => handlePlay({ shuffle: true })}
                      >
                        Shuffle
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <TrackList data={tracks ?? []} />
        </Grid>
      </Grid>
    </Container>
  );
}
