import { useState, useEffect } from 'react';

import _ from 'lodash';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  Button,
  TextField,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Alert,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from 'src/components/iconify';
import * as ReleaseService from 'src/services/ReleaseService';
import { useLibrary } from 'src/contexts/library';
import MyArtistSelect from 'src/components/MyArtistSelect';
import TokenGateBuilder from 'src/components/TokenGateBuilder';
import useTokenResolver from 'src/hooks/useTokenResolver';
import { sanitizeCid } from 'src/utils/ipfs';
import AddFilesModal from '../add-files-view';
import SortableList from '../sortable-list';
import PlaylistTypeSelect from './PlaylistTypeSelect';
import GenreTypeSelect from './GenreTypeSelect';
import ReleaseDatePicker from './ReleaseDatePicker';
import { IPFSImagePicker as ImagePicker } from '../ipfs-picker';

// routes
// components

const ReleaseForm = ({ data, setSelection }) => {
  const router = useRouter();

  const { address } = useLibrary();
  const confirm = useBoolean();

  const [showAddAnotherTrack, setShowAddAnotherTrack] = useState(null);
  const [showAddFilesModal, setShowAddFilesModal] = useState(null);
  const [sourceID, setSourceID] = useState(null);
  const [accessGroups, setAccessGroups] = useState([]);
  const { metadata: tokenMetadata } = useTokenResolver(sourceID);

  const [files, setFiles] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);

  const [owner, setOwner] = useState('');
  const [title, setTitle] = useState('');
  const [artist, setArtist] = useState(null);
  const [type, setType] = useState('album');
  const [genre, setGenre] = useState('None');
  const [releaseDate, setReleaseDate] = useState(null);

  const canEdit = _.isEmpty(data) || owner === address;
  useEffect(() => {
    const { artistRef, tracks, ...album } = data ?? {};
    setOwner(album.owner);
    setSourceID(album?.nftId);
    setTitle(album?.title ?? '');
    setArtist(artistRef?.id);
    setGenre(album?.genre?.toLowerCase());
    setReleaseDate(album?.releaseDate?.toDate?.());
    setAccessGroups(album?.accessGroups);
    setThumbnail({ id: sanitizeCid(album?.thumbnail) });
    setFiles(tracks);
  }, [data]);

  useEffect(() => {
    if (tokenMetadata?.title && title?.length === 0) setTitle(tokenMetadata?.title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenMetadata]);

  useEffect(() => {
    if (accessGroups?.length === 0 && sourceID?.length > 0) {
      setAccessGroups([sourceID]);
      console.log('Setting up access groups: ', accessGroups);
    } else {
      console.log('no source id: ', sourceID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceID]);

  const onNext = (id) => router?.replace(paths.dashboard.explore);
  const onBack = () => setSelection(false);

  const albumId = data?.id;

  // props used to save & update
  const newAlbumProps = {
    title,
    genre,
    releaseDate,
    artist,
    files,
    accessGroups,
    thumbnail: thumbnail?.id,
    owner: address,
    nftId: sourceID,
    type: type?.toLowerCase(),
  };

  const save = async () => {
    try {
      await ReleaseService.createReleaseWithTracks(newAlbumProps);
      onNext();

      enqueueSnackbar(`Created release!`, { variant: 'success' });
    } catch (error) {
      console.error('Error saving release: ', error, newAlbumProps);
      enqueueSnackbar(`Failed to save release ${error?.message ?? ''}`, { variant: 'error' });
    }
  };

  if (!canEdit)
    return (
      <Alert severity="error" sx={{ mb: 3 }}>
        Only the owner can make changes to this release.
      </Alert>
    );

  const renderFiles = (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <ImagePicker sourceId={sourceID} file={thumbnail?.id} setImage={setThumbnail} />
      </Grid>
      <Grid item xs={9}>
        <Stack spacing={2}>
          <TextField
            label="Title"
            placeholder="Title of your release"
            size="small"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <MyArtistSelect value={artist} onChange={setArtist} />
          <PlaylistTypeSelect value={type} onChange={(e) => setType(e)} />
          <GenreTypeSelect value={genre} onChange={(e) => setGenre(e)} />
          <ReleaseDatePicker value={releaseDate} onChange={(e) => setReleaseDate(e)} />

          <Divider />

          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography variant="h5" sx={{ fontWeight: 'normal' }}>
                Token Gate:
              </Typography>
              <Typography variant="body2" color="text.secondary">
                People who hold any of the selected tokens, (or tokens in collection), will be able
                to listen to this release.
              </Typography>
            </Stack>
            <TokenGateBuilder
              value={accessGroups}
              onChange={(newValue) => setAccessGroups(newValue)}
            />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2}>
          <SortableList
            rows={files}
            onChange={setFiles}
            handleClick={() => {
              setShowAddAnotherTrack(true);
            }}
          />
        </Stack>
      </Grid>
    </Grid>
  );

  const canProceed =
    files?.length !== 0 && title?.length > 0 && !_.isEmpty(artist) && !_.isEmpty(thumbnail);

  return (
    <Stack spacing={2}>
      <Grid container spacing={4} textAlign="start">
        <Grid item xs={12}>
          <Card>
            <CardHeader name="Edit Release" subheader="Make changes to this release." />

            <CardContent>
              <Stack padding={1} spacing={2}>
                {renderFiles}
              </Stack>
              <Stack direction="row" spacing={1} width="100%">
                <Button variant="outlined" fullWidth onClick={onBack}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={save}
                  disabled={!canProceed}
                >
                  Publish
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <AddFilesModal
        sourceId={sourceID}
        files={files}
        open={showAddFilesModal}
        onClose={() => setShowAddFilesModal(false)}
        // cid={source}
        onAddFiles={(e) => {
          setShowAddFilesModal(false);
          setFiles(e);
        }}
      />

      <AddFilesModal
        sourceId={sourceID}
        files={[]} // leave blank when adding more files
        open={showAddAnotherTrack}
        onClose={() => setShowAddAnotherTrack(false)}
        // cid={source}
        onAddFiles={(e) => {
          setShowAddAnotherTrack(false);
          setFiles((prev) => [...prev, ...e]);
        }}
      />

      {albumId && (
        <>
          <Divider />

          <ConfirmDialog
            open={confirm.value}
            onClose={confirm.onFalse}
            title="Delete"
            content={
              <>
                Are you sure want to delete <strong> {title} </strong>?
              </>
            }
            action={
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  ReleaseService.deleteRelease(albumId).then(() => {
                    confirm.onFalse();
                    router.back();
                  });
                }}
              >
                Delete
              </Button>
            }
          />
          <Button color="error" onClick={confirm.onTrue} fullWidth>
            Delete
            <Iconify icon="solar:trash-bin-trash-bold" />
          </Button>
        </>
      )}
    </Stack>
  );
};

export default ReleaseForm;
