import { Helmet } from 'react-helmet-async';
// sections
import { VideosView } from 'src/sections/videos/view';

// ----------------------------------------------------------------------

export default function VideosPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Videos</title>
      </Helmet>

      <VideosView />
    </>
  );
}
