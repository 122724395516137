import { useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid, Card, CardHeader, CardContent, CardActionArea } from '@mui/material';
import { useSettingsContext } from 'src/components/settings';

import CreateImport from './import-existing-view';
import CreateMint from './mint-new-view';
import { createType } from './types';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function CreateView() {
  const settings = useSettingsContext();

  const [selection, setSelection] = useState(false);

  const renderContent = () => {
    switch (selection) {
      case 'import':
        return <CreateImport setSelection={setSelection} />;
      case 'mint':
        return <CreateMint setSelection={setSelection} />;
      default:
        return <CreateHome setSelection={setSelection} />;
    }
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack spacing={2} alignItems="center" textAlign="center">
        <Typography variant="h2" component="h1">
          Create
        </Typography>
        <Typography color="text.secondary">
          Publish / link your content on MintStream to make it accessible for holders to enjoy here,
          and folks to find!
        </Typography>

        {renderContent()}
      </Stack>
    </Container>
  );
}

const CreateHome = ({ setSelection }) => (
  <Grid container spacing={4} textAlign="start">
    {createType.map((e) => (
      <Grid item key={e.name} xs={12} md={6}>
        <Card>
          <CardActionArea
            onClick={() => (e.url ? window.open(e.url, '_blank') : setSelection(e.value))}
          >
            <CardHeader
              title={
                <Typography variant="h4" component="h2">
                  {e.name}
                </Typography>
              }
            />
            <CardContent>
              <Typography variant="p" component="h3">
                {e.description}
              </Typography>
              <Typography variant="body2" component="p">
                <ul>
                  {e.steps.map((step) => (
                    <li>{step}</li>
                  ))}
                </ul>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    ))}
  </Grid>
);
