export const createType = [
  {
    name: '🚚 Import Existing',
    value: 'import',
    description: 'Import existing media from on-chain NFTs or IPFS.',
    steps: ['Choose from collection, token, or CID', 'Verify Metadata', 'Publish'],
  },
  {
    name: '🏭 Mint New',
    value: 'mint',
    url: 'https://designer.nfttoolk.it/audio',
    description: 'Use our interactive token designer to create & mint in minutes.',
    steps: ['Select a template', 'Upload your assets', 'Configure metadata', 'Customize & Mint'],
  },
];

export const sourceType = [
  {
    name: '🎧 Audio',
    value: 'audio',
    description: 'Add songs as singles or albums.',
  },
  {
    name: '📺 Video',
    value: 'video',
    description: 'Import videos and movies as streamable.',
  },
];
