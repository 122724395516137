import { Stack, Container, Typography } from '@mui/material';
import _ from 'lodash';

import { useSettingsContext } from 'src/components/settings';
import TrackList from 'src/components/track-list';
import { useLibrary } from 'src/contexts/library';
import SuggestConnect from 'src/components/SuggestConnect';
import EmptyContent from 'src/components/empty-content';

// ----------------------------------------------------------------------

export default function ExploreView() {
  const settings = useSettingsContext();
  const { address, tracks } = useLibrary();

  if (!address) return <SuggestConnect title="My Songs | Connect to view" />;

  if (_.isEmpty(tracks))
    return (
      <EmptyContent
        filled
        title="No Releases Found"
        description={
          <>
            Consider going shopping on <a href="https://loopexchange.art">LoopExchange</a> for some
            great music!
            <br />
            🛍️
          </>
        }
        sx={{ py: 10, m: 2 }}
      />
    );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Typography variant="h1">Songs</Typography>

      <Stack spacing={2}>
        <TrackList data={tracks} />
      </Stack>
    </Container>
  );
}
