import { db } from 'src/utils/firebase';

const RELEASES = 'releases';
export const releasesCollection = db.collection(RELEASES);
export const ReleaseRef = (id) => releasesCollection.doc(id);

const ARTISTS = 'artists';
export const artistsCollection = db.collection(ARTISTS);
export const ArtistRef = (id) => artistsCollection.doc(id);

const TRACKS = 'tracks';
export const tracksCollection = db.collection(TRACKS);
export const TrackRef = (id) => tracksCollection.doc(id);

const PLAYLISTS = 'playlists';
export const playlistsCollection = db.collection(PLAYLISTS);
export const PlaylistRef = (id) => playlistsCollection.doc(id);

const LOGS = 'logs';
export const logsCollection = db.collection(LOGS);
export const LogRef = (id) => logsCollection.doc(id);

// const VIDEOS = 'videos';
// export const videosCollection = db.collection(VIDEOS);
// export const VideoRef = (id) => videosCollection.doc(id);
