import React from 'react';
import { LinearProgress, Stack, Typography, Slider, AppBar, Toolbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import ShuffleOnIcon from '@mui/icons-material/ShuffleOn';
// import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { styled } from '@mui/material/styles';

import { useLibrary } from 'src/contexts/library';
import IPFSImage from 'src/components/ipfs-image';

const ProgressBar = styled(Slider)(({ theme }) => ({
  width: '100%',
}));

export default function PlayerBar() {
  const { player } = useLibrary();
  const { handleScrub, track } = player;
  const { album, artist, ...song } = track ?? {};
  const albumArtUrl = album?.thumbnail;

  const isPlayerHidden = track === null;

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{
        top: 'auto',
        bottom: 0,
        transition: 'transform 0.3s ease',
        transform: isPlayerHidden ? 'translateY(100%)' : 'translateY(0%)',
      }}
    >
      <Toolbar disableGutters>
        <Stack justifyContent="start" width="100%">
          {player.loading ? (
            <LinearProgress />
          ) : (
            <ProgressBar
              sx={{
                top: 0,
                height: 5,
                paddingTop: 0,
                paddingBottom: 0,
                color: 'primary',
                '& .MuiSlider-track': {
                  border: 'none',
                },
                '& .MuiSlider-thumb': {
                  width: 4,
                  height: 4,
                  '& .MuiSlider-thumb': {
                    width: 8,
                    height: 8,
                    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                    '&:before': {
                      boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                    },
                    '&:hover, &.Mui-focusVisible': {
                      boxShadow: `0px 0px 0px 8px 'rgb(0 0 0 / 16%)`,
                    },
                    '&.Mui-active': {
                      width: 20,
                      height: 20,
                    },
                  },
                },
              }}
              padding={0}
              variant="determinate"
              value={player.progress}
              onChange={(e) => handleScrub('onChange', e.target.value)}
              onChangeCommitted={(e) => handleScrub('onEnd', e.target.value)} // for example fetching new data
            />
          )}

          <Stack
            direction="row"
            alignItems="center"
            padding={1}
            justifyContent="space-between"
            spacing={1}
            width="100%"
          >
            <Stack
              direction="row"
              sx={{ width: '33%' }}
              spacing={1}
              alignItems="start"
              justifyContent="start"
            >
              <IPFSImage
                src={albumArtUrl}
                sx={{
                  width: '44px',
                  height: '44px',
                  borderRadius: '4px',
                  backgroundColor: albumArtUrl ? 'transparent' : 'gray',
                  backgroundImage: albumArtUrl ? `url(${albumArtUrl})` : 'none',
                }}
                alt="Album Art"
              />
              <Stack justifyContent="center" maxHeight={50}>
                <Typography variant="body">{song?.name}</Typography>
                <Typography variant="body2">{artist?.name}</Typography>
              </Stack>
            </Stack>

            {/* Center the buttons horizontally */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center" // This centers the buttons horizontally
              spacing={1}
              sx={{ width: '33%' }}
            >
              <IconButton onClick={player.onPrevious}>
                <SkipPreviousIcon />
              </IconButton>
              <IconButton onClick={player.togglePlayback}>
                {player.playing ? <PauseCircleFilledIcon /> : <PlayCircleFilledIcon />}
              </IconButton>
              <IconButton onClick={() => player.onNext()}>
                <SkipNextIcon />
              </IconButton>
            </Stack>

            <Stack direction="row" sx={{ width: '33%' }} alignItems="end" justifyContent="end">
              <IconButton onClick={() => player.toggleShuffle()}>
                {player.shuffle ? <ShuffleOnIcon /> : <ShuffleIcon />}
              </IconButton>
              {/* <IconButton>
            <VolumeUpIcon />
          </IconButton> */}
            </Stack>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
