import { useState, useEffect } from 'react';

import { Stack, Box, Button, Typography, Modal } from '@mui/material';

import useLoopring from 'src/hooks/useLoopring';
import useTokenResolver from 'src/hooks/useTokenResolver';
import { sanitizeCid } from 'src/utils/ipfs';
import NFTSelect from 'src/components/NFTSelect';
import { IPFSFilePicker } from './ipfs-picker';
import { fileNameToTitle } from './utils';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AddFiles({
  sourceId: _sourceId,
  cid: _cid,
  limit,
  open,
  onClose,
  contentType = 'audio',
  onAddFiles,
}) {
  const { mints } = useLoopring();
  const [sourceId, setSourceId] = useState(_sourceId);

  const isCid = _cid?.length > 0 || sourceId?.startsWith('Qm');
  const cid = _cid;

  useEffect(() => setSourceId(_sourceId), [_sourceId]);

  const [files, setFiles] = useState([]);
  const { metadata: tokenMetadata } = useTokenResolver(!isCid ? sourceId : null);

  const toggleFile = (file) => {
    const contained = files.find((e) => e.id === file.id);

    let _files;
    if (contained) {
      _files = files.filter((e) => e.id !== file.id);
    } else {
      _files = [...files, file];
    }

    setFiles(_files);
  };

  const onAdd = () => {
    onAddFiles(files);
    setFiles([]);
  };

  const addAll = (_files) =>
    setFiles(
      _files.map((file) => ({
        ...file,
        name: fileNameToTitle(file?.name ?? tokenMetadata?.name),
      }))
    );

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" gutterBottom>
          {/* Select {_.capitalize(contentType ?? '') ?? 'Assets'} */}
          Select Assets
        </Typography>
        {/* <Typography id="modal-modal-title" variant="b" component="p" gutterBottom>
          Select {contentType} files from interactive NFTs.
        </Typography> */}

        <Stack spacing={2}>
          <NFTSelect
            // isLoading={loading}
            rows={mints}
            value={sourceId}
            onChange={(e) => {
              setSourceId(e.target.value);
            }}
          />

          <IPFSFilePicker
            limit={limit}
            cid={isCid ? cid : sanitizeCid(tokenMetadata?.animation_url)}
            imageCid={isCid ? cid : sanitizeCid(tokenMetadata?.image)}
            contentType={contentType}
            selection={files.map((e) => e.id)}
            onFileClick={toggleFile}
            onAddAll={addAll}
          />

          <Stack direction="row" spacing={1}>
            <Button variant="outlined" fullWidth onClick={onClose}>
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={files?.length === 0}
              onClick={onAdd}
            >
              Add {files?.length} File{files?.length > 1 && 's'}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
