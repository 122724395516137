import _ from 'lodash';
import { Grid, Container, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { useSettingsContext } from 'src/components/settings';
import usePlaylist from 'src/services/usePlaylist';
import * as PlaylistService from 'src/services/PlaylistService';
import TrackList from 'src/components/track-list';
import { db } from 'src/utils/firebase'; // Import your Firestore instance
import EmptyContent from 'src/components/empty-content';

export default function PlaylistView() {
  const settings = useSettingsContext();
  const { id } = useParams();
  const { playlist, tracks } = usePlaylist(id);

  if (_.isEmpty(tracks))
    return (
      <EmptyContent
        filled
        title="No Songs"
        description={
          <>
            It sure is quiet in here...
            <br />
            Consider going shopping on <a href="https://loopexchange.art">LoopExchange</a> for some
            great music!
            <br />
            🛍️
          </>
        }
        sx={{ py: 10, m: 2 }}
      />
    );

  const onReorder = async (trackObjects) => {
    const newOrder = trackObjects.map((t) => db.collection('tracks').doc(t.id));
    PlaylistService.reorderPlaylist(id, newOrder);
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'} margin={2}>
      <Typography variant="h1">{playlist?.name}</Typography>

      <Grid item xs={12}>
        <TrackList data={tracks} playlist={playlist} onReorder={onReorder} />
      </Grid>
    </Container>
  );
}
