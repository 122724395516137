import { useMemo } from 'react';

import { paths } from 'src/routes/paths';
import { useLocales } from 'src/locales';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { useLibrary } from 'src/contexts/library';

// routes
// locales
// components

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  music: <Iconify icon="fa6-solid:music" />,
  add: <Iconify icon="material-symbols:add-circle-outline-rounded" />,
  playlist: <Iconify icon="mdi:playlist-music" />,
  explore: <Iconify icon="material-symbols:browse" />,
  recent: <Iconify icon="mdi:clock-time-nine-outline" />,
  artist: <Iconify icon="mdi:microphone-variant" />,
  album: <Iconify icon="mdi:album" />,
  create: <Iconify icon="mdi:art" />,
  movie: <Iconify icon="mdi:movie-open" />,
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const { playlists } = useLibrary();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('🍵 MintStream'),
        items: [
          { title: t('Explore'), path: paths.dashboard.explore, icon: ICONS.explore },
          { title: t('Create'), path: paths.dashboard.create, icon: ICONS.create },
        ],
      },
      {
        subheader: t('Library'),
        items: [
          { title: t('Recently Added'), path: paths.dashboard.recentlyAdded, icon: ICONS.recent },
          { title: t('Artists'), path: paths.dashboard.artists, icon: ICONS.artist },
          { title: t('Albums'), path: paths.dashboard.albums.root, icon: ICONS.album },
          { title: t('Songs'), path: paths.dashboard.songs, icon: ICONS.music },
          { title: t('Videos'), path: paths.dashboard.videos, icon: ICONS.movie },
        ],
      },
      {
        subheader: t('Playlists'),
        path: paths.dashboard.playlist.root,
        icon: ICONS.playlist,
        items: [
          ...playlists.map(({ name, id }) => ({
            title: t(name),
            path: `${paths.dashboard.playlist.details(id)}`,
            icon: ICONS.playlist,
          })),
          { title: t('New Playlist'), path: paths.dashboard.playlist.new, icon: ICONS.add },
        ],
      },
    ],
    [t, playlists]
  );

  return data;
}
