import React from 'react';

import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import VideoCard from 'src/components/video-card';
import EmptyContent from 'src/components/empty-content';

export default function VideoList({ data, onClick }) {
  const navigate = useNavigate();
  const handleClick = (id) => navigate(`/dashboard/watch/${id}`);

  if (_.isEmpty(data))
    return <EmptyContent filled title="No videos" description="☹️" sx={{ py: 10, m: 2 }} />;

  return (
    <Grid container spacing={2}>
      {(data ?? []).map((e) => (
        <Grid item key={e.id} xs={6} md={3} lg={2}>
          <VideoCard data={e} onClick={onClick ?? handleClick} />
        </Grid>
      ))}
    </Grid>
  );
}
