import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const musicGenres = [
  { label: 'Pop', value: 'pop' },
  { label: 'Rock', value: 'rock' },
  { label: 'Hip-Hop', value: 'hip-hop' },
  { label: 'Jazz', value: 'jazz' },
  { label: 'Classical', value: 'classical' },
  { label: 'R&B', value: 'r&b' },
  { label: 'Country', value: 'country' },
  { label: 'Electronic', value: 'electronic' },
  { label: 'Blues', value: 'blues' },
  { label: 'Reggae', value: 'reggae' },
  { label: 'Metal', value: 'metal' },
  { label: 'Folk', value: 'folk' },
  { label: 'Punk', value: 'punk' },
  { label: 'Rap', value: 'rap' },
  { label: 'Soul', value: 'soul' },
  { label: 'Funk', value: 'funk' },
  { label: 'EDM (Electronic Dance Music)', value: 'edm' },
  { label: 'Alternative', value: 'alternative' },
  { label: 'Gospel', value: 'gospel' },
  { label: 'Indie', value: 'indie' },
  { label: 'Ambient', value: 'ambient' },
  { label: 'House', value: 'house' },
  { label: 'Techno', value: 'techno' },
  { label: 'Dance', value: 'dance' },
  { label: 'Disco', value: 'disco' },
].sort((a, b) => a.label.localeCompare(b.label));

const findGenre = (v) => musicGenres.find((e) => e.value === v?.toLowerCase())?.value ?? 'none';

const GenreTypeSelect = ({ value, onChange = console.log }) => {
  const [selectedGenre, setSelectedGenre] = useState(findGenre(value));

  useEffect(() => setSelectedGenre(findGenre(value)), [value]);

  const handleGenreChange = (event) => {
    const selectedValue = event.target.value?.toLowerCase(); // Convert to lowercase
    setSelectedGenre(selectedValue);

    // Pass the selected value to the parent component
    onChange(selectedValue);
  };

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor="genre-select">Music Genre</InputLabel>
      <Select
        size="small"
        label="Music Genre"
        inputProps={{
          name: 'genre',
          id: 'genre-select',
        }}
        value={selectedGenre}
        onChange={handleGenreChange}
      >
        <MenuItem value="none">None</MenuItem>
        {musicGenres.map((genre, index) => (
          <MenuItem key={index} value={genre.value}>
            {genre.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GenreTypeSelect;
