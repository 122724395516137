import { Helmet } from 'react-helmet-async';
// sections
import { AlbumsView } from 'src/sections/albums/view';

// ----------------------------------------------------------------------

export default function OverviewAppPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Albums</title>
      </Helmet>

      <AlbumsView />
    </>
  );
}
