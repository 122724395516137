import { useState, useMemo, createContext } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TableRow, TableBody } from '@mui/material';

export const HoveredContext = createContext();

export const HoveredProvider = ({ children }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const value = useMemo(
    () => ({
      hoveredIndex,
      setHoveredIndex,
    }),
    [hoveredIndex]
  );

  return <HoveredContext.Provider value={value}>{children}</HoveredContext.Provider>;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
});

export const DraggableComponent =
  (id, index) =>
  ({ children, ...props }) =>
    (
      <Draggable draggableId={id} index={index} sx={{ cursor: 'default' }}>
        {(provided, snapshot) => (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
              cursor: 'default',
            }}
            {...props}
          >
            {children}
          </TableRow>
        )}
      </Draggable>
    );

export const DroppableComponent =
  (onDragEnd) =>
  ({ children, ...props }) =>
    (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="1" direction="vertical">
          {(provided) => (
            <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
              {children}
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
    );
