import { useState, useEffect } from 'react';

import _ from 'lodash';
import Bugsnag from '@bugsnag/js';

import { db, getTimestamp } from 'src/utils/firebase';
import { Cid } from 'src/utils/ipfs';
import { ArtistRef, TrackRef, ReleaseRef, releasesCollection } from 'src/models';
import * as SongService from './SongService';
import * as LogService from './LogService';

export const createVideoRelease = async (data) => {
  const { artist, owner, thumbnail, files } = data;
  try {
    // Create the album document in Firestore with a unique ID
    const releaseRef = releasesCollection.doc(); // Create a Firestore reference without ID
    const releaseId = releaseRef.id; // Get the automatically generated ID
    const artistRef = ArtistRef(artist);

    if (_.isEmpty(files)) throw new Error('Failed to retrieve files');

    const sanitizedFiles = files?.map((file) => ({
      owner,
      name: file.name,
      fileId: Cid(file.id),
    }));

    // Update the album with file IDs
    await releaseRef.set({
      ..._.omit(data, ['files', 'artist']),
      artistRef,
      thumbnail: Cid(thumbnail),
      createdAt: getTimestamp(),
      videos: sanitizedFiles,
    });

    return releaseId; // Return the ID of the new release
  } catch (error) {
    Bugsnag.notify(error);

    console.error('Error creating album with tracks:', error);

    LogService.error({
      data: { ..._.omit(data, ['releaseDate']), initialFiles: files },
      event: 'CREATE_RELEASE_WITH_VIDEO',
      error,
      createdAt: getTimestamp(),
    });

    throw error;
  }
};

// Function to create an album with tracks
export const createReleaseWithTracks = async (albumData) => {
  const { artist, owner, thumbnail, files } = albumData;
  try {
    // Create the album document in Firestore with a unique ID
    const albumRef = releasesCollection.doc(); // Create a Firestore reference without ID
    const albumId = albumRef.id; // Get the automatically generated ID
    const artistRef = ArtistRef(artist);

    if (_.isEmpty(files)) throw new Error('Failed to retrieve files');

    const tracks = files?.map((file) => ({
      albumRef,
      artistRef,
      owner,
      name: file.name,
      fileId: Cid(file.id),
      createdAt: getTimestamp(),
    }));

    // Wait for all track creation promises to resolve
    const trackIds = await Promise.all(tracks.map(SongService.createTrack));

    // Update the album with track IDs
    await albumRef.set({
      ..._.omit(albumData, ['files', 'artist']),
      artistRef,
      thumbnail: Cid(thumbnail),
      createdAt: getTimestamp(),
      trackRefs: trackIds.map(TrackRef),
    });

    return albumId; // Return the album ID
  } catch (error) {
    Bugsnag.notify(error);

    console.error('Error creating album with tracks:', error);

    LogService.error({
      data: { ..._.omit(albumData, ['releaseDate']), initialFiles: files },
      event: 'CREATE_RELEASE_WITH_TRACKS',
      error,
      createdAt: getTimestamp(),
    });

    throw error;
  }
};

const sanitize = (data) => _.omit(data, ['createdAt', 'releaseDate']);

// Function to update an existing album with new tracks
export const updateReleaseWithTracks = async (albumId, albumData) => {
  try {
    // Get the album document reference from Firestore
    const albumRef = ReleaseRef(albumId);

    let trackRefs;
    const tracks = [];
    // Update or create the tracks
    // If there are files (tracks) to add, create them
    if (albumData.files && albumData.files.length > 0) {
      // Create an array of promises for creating tracks
      const createTrackPromises = albumData.files.map(async (file) => {
        const exists = file?.fileId && file?.id;
        console.log(file.name, exists ? 'exists' : "doesn't exist");
        const newTrackData = {
          albumRef,
          album: sanitize(albumData),
          artistRef: ArtistRef(albumData.artist),
          name: file.name,
          fileId: Cid(file.id),
          createdAt: getTimestamp(),
        };

        const existingTrackData = {
          ...file,
          albumRef,
          album: sanitize(albumData),
          artistRef: ArtistRef(albumData.artist),
          name: file.name,
          fileId: Cid(file.id),
        };

        // logic to detect if it's a new track data
        const track = exists ? existingTrackData : newTrackData;
        const trackId = await SongService.saveTrack(track);
        tracks.push({ id: trackId, ...sanitize(track) });
        return TrackRef(trackId); // Return the track document reference
      });

      // Wait for all track creation promises to resolve
      trackRefs = await Promise.all(createTrackPromises);
    }

    // Update the album document with new data, omitting files and artist
    // and sanitizing / stripping null values
    const newData = _.pickBy(
      {
        ..._.omit(albumData, ['files', 'tracks', 'artist']),
        updatedAt: getTimestamp(), // Use an updated timestamp
        artistRef: ArtistRef(albumData.artist),
        // Only update the thumbnail if it's provided in albumData
        ...(albumData.thumbnail && { thumbnail: Cid(albumData.thumbnail) }),
      },
      _.identity
    );

    // Append the new track references to the album's trackRefs array
    await albumRef.update({ ...newData, tracks, trackRefs }, { merge: true });

    return albumRef.id; // Return the updated album ID
  } catch (error) {
    console.error('Error updating album with tracks:', error);
    throw error;
  }
};

export const getRelease = async (id) => {
  const doc = await ReleaseRef(id).get();

  if (!doc.exists) return null;

  let result = { id };
  const data = doc.data();
  if (data?.releaseType !== 'video') {
    result = { id, ...enrichTracksInAlbum(data) };
  } else {
    result = { id, ...data };
  }

  return result;
};

const enrichTracksInAlbum = (data) => ({
  ...data,
  tracks: data.tracks.map((t) => ({ ...t, album: data, artist: data.artist })),
});

export const getReleases = async (albumRefs) => {
  console.log('Resolving releases list...');
  if (!albumRefs || albumRefs.length === 0) {
    return []; // No track references provided, return an empty array
  }

  try {
    const resolvedAlbums = await db.runTransaction(async (transaction) => {
      const albums = [];

      // Gather all promises from the transaction operations
      const promises = albumRefs.map(async (albumRef) => {
        const albumDoc = ReleaseRef(albumRef.id);
        const doc = await transaction.get(albumDoc);

        if (doc.exists) {
          albums.push({ id: doc.id, ...doc.data() });
        }
      });

      // Wait for all transaction operations to complete
      await Promise.all(promises);

      return albums;
    });

    return resolvedAlbums;
  } catch (error) {
    console.error(error);
    return []; // Return an empty array in case of error
  }
};

export const deleteRelease = async (id, withTracks = true) => {
  try {
    console.log('Deleting album with ID:', id);

    // Reference to the album document
    const albumRef = ReleaseRef(id);

    if (withTracks) {
      // If you have a Cloud Function that automatically deletes tracks when an album is deleted,
      // you just need to delete the album document and the function will take care of the tracks.
      await albumRef.delete();
    } else {
      // If you do not want to delete the tracks, you might want to update the album document
      // to disassociate it from the tracks first, or handle it as per your business logic.
      // For example:
      await albumRef.update({
        trackRefs: [], // Remove track references, or any other fields you want to clear.
      });
      await albumRef.delete(); // Then delete the album.
    }

    console.log('Album deleted successfully.');
  } catch (error) {
    console.error('Error deleting album:', error);
    throw error;
  }
};

export const useRelease = (albumId) => {
  const [album, setAlbum] = useState(null);

  useEffect(() => {
    getRelease(albumId).then((data) => {
      if (data) {
        setAlbum(data);
      } else {
        // Reset state if album not found
        setAlbum(null);
      }
    });
    // eslint-disable-next-line
  }, [albumId]);

  return album;
};

export const getVideo = async (id) => {
  const doc = await ReleaseRef(id).get();
  if (!doc.exists) return null;
  return { id, ...doc.data() };
};

export const useVideo = (id) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    getVideo(id).then((res) => {
      if (res) {
        setData(res);
      } else {
        // Reset state if album not found
        setData(null);
      }
    });
    // eslint-disable-next-line
  }, [id]);

  return data;
};
