import { useState, useEffect } from 'react';
import {
  parseCollectionId,
  fetchTokenMetadata,
  fetchTokenCollectionMetadata,
} from 'src/hooks/useTokenResolver';

export const useTokenGates = (nftIds) => {
  const [loading, setLoading] = useState(false);
  const [resolvedTokenMetadata, setResolvedTokenMetadata] = useState({});
  const [resolvedCollectionMetadata, setResolvedCollectionMetadata] = useState({});

  useEffect(() => {
    const resolveMints = async () => {
      setLoading(true);
      const tokenResults = {};
      const collectionResults = {};

      await Promise.all(
        nftIds.map(async (nftId) => {
          const tokenMetadata = await fetchTokenMetadata(nftId);

          if (tokenMetadata) {
            tokenResults[nftId] = tokenMetadata;

            const collectionId = parseCollectionId(tokenMetadata?.collection_metadata);
            if (collectionId) {
              const collectionMetadata = await fetchTokenCollectionMetadata(tokenMetadata);
              collectionResults[collectionId] = collectionMetadata;
            }
          }
        })
      );

      setResolvedTokenMetadata(tokenResults);
      setResolvedCollectionMetadata(collectionResults);
      setLoading(false);
    };

    resolveMints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftIds]);

  return { loading, collections: resolvedCollectionMetadata, tokens: resolvedTokenMetadata };
};
