import { lazy, Suspense } from 'react';

import { Outlet } from 'react-router-dom';

import DashboardLayout from 'src/layouts/dashboard';
import { LoadingScreen } from 'src/components/loading-screen';

// auth
// import { AuthGuard } from 'src/auth/guard';
// layouts
// components

// ----------------------------------------------------------------------

// OVERVIEW
const CreatePage = lazy(() => import('src/pages/dashboard/create'));
const ExplorePage = lazy(() => import('src/pages/dashboard/explore'));
const RecentlyAddedPage = lazy(() => import('src/pages/dashboard/recently-added'));
const ArtistsPage = lazy(() => import('src/pages/dashboard/artists'));
const AlbumsPage = lazy(() => import('src/pages/dashboard/albums'));
const AlbumDetailPage = lazy(() => import('src/pages/dashboard/album-details'));
const AlbumEditPage = lazy(() => import('src/pages/dashboard/album-edit'));
const SongsPage = lazy(() => import('src/pages/dashboard/songs'));
const VideosPage = lazy(() => import('src/pages/dashboard/videos'));
const PlaylistPage = lazy(() => import('src/pages/dashboard/playlist'));
const NewPlaylistPage = lazy(() => import('src/pages/dashboard/new-playlist'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const WatchPage = lazy(() => import('src/pages/dashboard/watch'));

// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      // <AuthGuard>
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
      // </AuthGuard>
    ),
    children: [
      { element: <ExplorePage />, index: true },
      { path: 'explore', element: <ExplorePage /> },
      { path: 'create', element: <CreatePage /> },
      { path: 'recently-added', element: <RecentlyAddedPage /> },
      { path: 'artists', element: <ArtistsPage /> },
      { path: 'artists/:id', element: <ArtistsPage /> },
      { path: 'albums', element: <AlbumsPage /> },
      { path: 'albums/:id', element: <AlbumDetailPage /> },
      { path: 'albums/:id/edit', element: <AlbumEditPage /> },
      { path: 'watch', element: <WatchPage /> },
      { path: 'watch/:id', element: <WatchPage /> },
      { path: 'watch/:id/edit', element: <AlbumEditPage /> },
      { path: 'songs', element: <SongsPage /> },
      { path: 'videos', element: <VideosPage /> },
      { path: 'playlist/:id', element: <PlaylistPage /> },
      { path: 'playlist/new', element: <NewPlaylistPage /> },
      // { path: 'ecommerce', element: <OverviewEcommercePage /> },
      // { path: 'analytics', element: <OverviewAnalyticsPage /> },
      // { path: 'banking', element: <OverviewBankingPage /> },
      // { path: 'booking', element: <OverviewBookingPage /> },
      // { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'user',
        children: [
          // { element: <UserProfilePage />, index: true },
          // { path: 'profile', element: <UserProfilePage /> },
          // { path: 'cards', element: <UserCardsPage /> },
          // { path: 'list', element: <UserListPage /> },
          // { path: 'new', element: <UserCreatePage /> },
          // { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      // {
      //   path: 'product',
      //   children: [
      //     { element: <ProductListPage />, index: true },
      //     { path: 'list', element: <ProductListPage /> },
      //     { path: ':id', element: <ProductDetailsPage /> },
      //     { path: 'new', element: <ProductCreatePage /> },
      //     { path: ':id/edit', element: <ProductEditPage /> },
      //   ],
      // },
      // {
      //   path: 'order',
      //   children: [
      //     { element: <OrderListPage />, index: true },
      //     { path: 'list', element: <OrderListPage /> },
      //     { path: ':id', element: <OrderDetailsPage /> },
      //   ],
      // },
      // {
      //   path: 'invoice',
      //   children: [
      //     { element: <InvoiceListPage />, index: true },
      //     { path: 'list', element: <InvoiceListPage /> },
      //     { path: ':id', element: <InvoiceDetailsPage /> },
      //     { path: ':id/edit', element: <InvoiceEditPage /> },
      //     { path: 'new', element: <InvoiceCreatePage /> },
      //   ],
      // },
      // {
      //   path: 'post',
      //   children: [
      //     { element: <BlogPostsPage />, index: true },
      //     { path: 'list', element: <BlogPostsPage /> },
      //     { path: ':title', element: <BlogPostPage /> },
      //     { path: ':title/edit', element: <BlogEditPostPage /> },
      //     { path: 'new', element: <BlogNewPostPage /> },
      //   ],
      // },
      // {
      //   path: 'job',
      //   children: [
      //     { element: <JobListPage />, index: true },
      //     { path: 'list', element: <JobListPage /> },
      //     { path: ':id', element: <JobDetailsPage /> },
      //     { path: 'new', element: <JobCreatePage /> },
      //     { path: ':id/edit', element: <JobEditPage /> },
      //   ],
      // },
      // {
      //   path: 'tour',
      //   children: [
      //     { element: <TourListPage />, index: true },
      //     { path: 'list', element: <TourListPage /> },
      //     { path: ':id', element: <TourDetailsPage /> },
      //     { path: 'new', element: <TourCreatePage /> },
      //     { path: ':id/edit', element: <TourEditPage /> },
      //   ],
      // },
      // { path: 'file-manager', element: <FileManagerPage /> },
      // { path: 'mail', element: <MailPage /> },
      // { path: 'chat', element: <ChatPage /> },
      // { path: 'calendar', element: <CalendarPage /> },
      // { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      // { path: 'blank', element: <BlankPage /> },
    ],
  },
];
