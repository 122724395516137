import { Helmet } from 'react-helmet-async';
// sections
import { SongsView } from 'src/sections/songs/view';

// ----------------------------------------------------------------------

export default function OverviewAppPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Songs</title>
      </Helmet>

      <SongsView />
    </>
  );
}
