import { Helmet } from 'react-helmet-async';
// sections
import { ArtistsView } from 'src/sections/artists/view';

// ----------------------------------------------------------------------

export default function OverviewAppPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Artists</title>
      </Helmet>

      <ArtistsView />
    </>
  );
}
