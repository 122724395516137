import { useState, useEffect } from 'react';

import _ from 'lodash';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  Button,
  TextField,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Alert,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
import * as ReleaseService from 'src/services/ReleaseService';
import useLoopring from 'src/hooks/useLoopring';
import NFTSelect from 'src/components/NFTSelect';
import MyArtistSelect from 'src/components/MyArtistSelect';
import TokenGateBuilder from 'src/components/TokenGateBuilder';
import useTokenResolver from 'src/hooks/useTokenResolver';
import { sanitizeCid } from 'src/utils/ipfs';
import AddFilesModal from '../add-files-view';
import SortableList from '../sortable-list';
import PlaylistTypeSelect from './PlaylistTypeSelect';
import GenreTypeSelect from './GenreTypeSelect';
import ReleaseDatePicker from './ReleaseDatePicker';
import { IPFSImagePicker as ImagePicker } from '../ipfs-picker';
import ReleaseTypeTabs from './release-type-tabs';

// routes
// components

const CreateImport = ({ setSelection }) => {
  const router = useRouter();

  const { address, mints } = useLoopring();

  const pageTitle = '🚚 Import Existing';
  const description = 'Import existing media from on-chain NFTs or IPFS.';

  const [showAddAnotherTrack, setShowAddAnotherTrack] = useState(null);
  const [showAddFilesModal, setShowAddFilesModal] = useState(null);
  const [sourceID, setSourceID] = useState('');
  const [ipfsCid, setIPFSCid] = useState('');
  const [accessGroups, setAccessGroups] = useState([]);
  const { metadata: tokenMetadata } = useTokenResolver(sanitizeCid(sourceID));

  const [showIPFSField, setShowIPFSField] = useState(false);

  const [files, setFiles] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);

  const [title, setTitle] = useState('');
  const [artist, setArtist] = useState('');
  const [type, setType] = useState('Album');
  const [genre, setGenre] = useState('None');
  const [releaseDate, setReleaseDate] = useState('');

  useEffect(() => {
    console.log('Retrieved metadata: ', sourceID, tokenMetadata);
    if (tokenMetadata?.title && title?.length === 0) setTitle(tokenMetadata?.title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenMetadata]);

  useEffect(() => {
    if (accessGroups?.length === 0 && sourceID?.length > 0) {
      setAccessGroups([sourceID]);
      console.log('Setting up access groups: ', accessGroups);
    } else {
      console.log('no source id: ', sourceID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceID]);

  const onNext = (id) => router?.replace(paths.dashboard.explore);
  const onBack = () => setSelection(false);

  const [releaseType, setReleaseType] = useState('audio');
  const containsVideo = files?.some((e) => e.contentType === 'video');
  const audioRelease = releaseType === 'audio';
  // const videoRelease = releaseType === 'video';

  const save = async () => {
    try {
      const newRelease = {
        title,
        genre,
        releaseDate,
        artist,
        files,
        releaseType,
        accessGroups,
        thumbnail: thumbnail.id,
        owner: address,
        nftId: sourceID,
        type: type.toLowerCase(),
      };

      // create the audio or video release in db
      if (audioRelease) await ReleaseService.createReleaseWithTracks(newRelease);
      else await ReleaseService.createVideoRelease(newRelease);

      onNext();

      enqueueSnackbar(`Created release!`, { variant: 'success' });
    } catch (error) {
      console.error('Error saving release: ', error, files);
      enqueueSnackbar(`Failed to save release ${error?.message ?? ''}`, { variant: 'error' });
    }
  };

  const canProceed =
    files?.length !== 0 &&
    ((title?.length > 0 && !_.isEmpty(artist) && !_.isEmpty(thumbnail)) || ipfsCid.length > 0);

  return (
    <>
      <Grid container spacing={4} textAlign="start">
        <Grid item xs={12}>
          <Card>
            <CardHeader
              name={
                <Typography variant="h4" component="h2">
                  {pageTitle}
                </Typography>
              }
              subheader={description}
            />

            <CardContent>
              <Stack padding={1} spacing={2}>
                {files.length === 0 ? (
                  <Stack alignItems="center" justifyContent="center" padding={8} spacing={2}>
                    <Typography variant="h5">Add files from an NFT</Typography>
                    <Typography variant="body2">
                      Works with interactive NFTs & mp4s
                      <br />
                      <small>
                        Create one now{' '}
                        <a href="https://designer.nfttoolk.it">designer.nfttoolk.it</a>
                      </small>
                    </Typography>
                    <NFTSelect
                      sx={{ minWidth: 200 }}
                      // isLoading={loading}
                      rows={mints}
                      value={sourceID}
                      onChange={(e) => setSourceID(e.target.value)}
                      fullWidth
                    />
                    {showIPFSField ? (
                      <TextField
                        label="IPFS CID (Qm...)"
                        onChange={(e) => setIPFSCid(e.target.value)}
                        value={ipfsCid}
                      />
                    ) : (
                      address && (
                        <>
                          <Button
                            size="small"
                            color="secondary"
                            onClick={() => setShowIPFSField((p) => !p)}
                          >
                            or use an IPFS cid
                          </Button>

                          <Button
                            disabled={!sourceID && ipfsCid.length === 0}
                            onClick={() => setShowAddFilesModal(true)}
                            fullWidth
                            sx={{ maxWidth: 200 }}
                            variant="contained"
                            color="primary"
                            size="large"
                          >
                            Add Files
                          </Button>
                        </>
                      )
                    )}
                  </Stack>
                ) : (
                  <>
                    {containsVideo && (
                      <ReleaseTypeTabs value={releaseType} onChange={setReleaseType} />
                    )}

                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <ImagePicker
                          cid={ipfsCid}
                          sourceId={ipfsCid?.length > 0 ? ipfsCid : sourceID}
                          file={thumbnail?.id}
                          setImage={setThumbnail}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Stack spacing={2}>
                          <TextField
                            label="Title"
                            placeholder="Title of your release"
                            size="small"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                          <MyArtistSelect value={artist} onChange={setArtist} />

                          {audioRelease && (
                            <>
                              <PlaylistTypeSelect onChange={(e) => setType(e)} />
                              <GenreTypeSelect onChange={(e) => setGenre(e)} />
                            </>
                          )}
                          <ReleaseDatePicker onChange={(e) => setReleaseDate(e)} />

                          <Divider />

                          <Stack spacing={1}>
                            <Stack spacing={0}>
                              <Typography variant="h5" sx={{ fontWeight: 'normal' }}>
                                Token Gate:
                              </Typography>
                              <Typography variant="body2">Who can access this release?</Typography>
                            </Stack>
                            {_.isEmpty(accessGroups) && (
                              <Alert severity="warning" sx={{ mb: 3 }}>
                                Add access groups so holders can listen to your music, and see it in
                                their library!
                              </Alert>
                            )}

                            <TokenGateBuilder
                              value={accessGroups}
                              onChange={(newValue) => setAccessGroups(newValue)}
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                      {audioRelease && (
                        <Grid item xs={12}>
                          <Stack spacing={2}>
                            <SortableList
                              handleClick={() => {
                                setShowAddAnotherTrack(true);
                              }}
                              rows={files}
                              onChange={setFiles}
                            />
                          </Stack>
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
              </Stack>
              <Stack direction="row" spacing={1} width="100%">
                <Button variant="outlined" fullWidth onClick={onBack}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={save}
                  disabled={!canProceed}
                >
                  Publish {audioRelease ? type : 'Video'}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <AddFilesModal
        sourceId={sourceID}
        cid={ipfsCid}
        files={files}
        open={showAddFilesModal}
        onClose={() => setShowAddFilesModal(false)}
        // cid={source}
        onAddFiles={(e) => {
          setShowAddFilesModal(false);
          setFiles(e);
        }}
      />
      <AddFilesModal
        sourceId={sourceID}
        cid={ipfsCid}
        files={files}
        open={showAddAnotherTrack}
        onClose={() => setShowAddAnotherTrack(false)}
        // cid={source}
        onAddFiles={(e) => {
          setShowAddAnotherTrack(false);
          setFiles((prev) => [...prev, ...e]);
        }}
      />
    </>
  );
};

export default CreateImport;
