import { useEffect, useState, useMemo } from 'react';
import { useAccount } from 'wagmi';
import { getAccountId, getMints, getNFTs } from 'src/api/loopring';

export default function useLoopring() {
  const { address } = useAccount();

  // Memoize the lowercase address to prevent unnecessary re-fetches
  const memoizedAddress = useMemo(() => address?.toLowerCase(), [address]);

  const [loading, setLoading] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [mints, setMints] = useState([]);
  const [nfts, setNFTs] = useState([]);

  useEffect(() => {
    if (memoizedAddress) {
      getAccountId(memoizedAddress)
        .then((r) => setAccountId(r.accountId))
        .catch(console.error);
    }
  }, [memoizedAddress]); // Depend on memoizedAddress

  useEffect(() => {
    const fetchData = async () => {
      if (accountId) {
        setLoading(true);

        try {
          const mintsData = await getMints(accountId);
          setMints(mintsData);

          const nftsData = await getNFTs(accountId);
          setNFTs(nftsData);
        } catch (error) {
          console.error(error);
        }

        setLoading(false);
      }
    };

    fetchData();
  }, [accountId]);

  // Return the memoized address
  return { address: memoizedAddress, accountId, mints, nfts, loading };
}
