import { Container, Typography } from '@mui/material';
import _ from 'lodash';

import { useLibrary } from 'src/contexts/library';
import AlbumGrid from 'src/components/album-grid';
import SuggestConnect from 'src/components/SuggestConnect';
import EmptyContent from 'src/components/empty-content';

export default function AlbumsView() {
  const { address, albums } = useLibrary();

  if (!address) return <SuggestConnect title="My Albums | Connect to view" />;

  if (_.isEmpty(albums))
    return (
      <EmptyContent
        filled
        title="No Releases Found"
        description={
          <>
            Consider going shopping on <a href="https://loopexchange.art">LoopExchange</a> for some
            great music!
            <br />
            🛍️
          </>
        }
        sx={{ py: 10, m: 2 }}
      />
    );

  return (
    <Container>
      <Typography variant="h1">Albums</Typography>
      <AlbumGrid data={albums} />
    </Container>
  );
}
