import * as React from 'react';
import { ListItemButton, ListItemText, ListItemAvatar } from '@mui/material';
import IPFSImage from 'src/components/ipfs-image';

export function ArtistRow({ selected, data, onClick }) {
  const { name, thumbnail } = data;

  return (
    <ListItemButton alignItems="center" onClick={onClick} selected={selected}>
      <ListItemAvatar>
        <IPFSImage src={thumbnail} sx={{ height: '44px', borderRadius: '100%' }} />
      </ListItemAvatar>
      <ListItemText primary={name} />
    </ListItemButton>
  );
}
