import React, { useContext, useState, useEffect } from 'react';

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
  IconButton,
  Typography,
  Stack,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { arrayMoveImmutable as arrayMove } from 'array-move';

import { useLibrary } from 'src/contexts/library';
import IPFSImage from 'src/components/ipfs-image';
import TrackContextMenu from 'src/components/track-context-menu';
import { useWidth } from 'src/hooks/use-responsive';
import {
  DraggableComponent,
  DroppableComponent,
  HoveredProvider,
  HoveredContext,
} from './drag-n-drop';

const columnText = (str) => <Typography>{str}</Typography>;

const columns = [
  {
    field: 'index',
    headerName: '#',
    renderCell: ({ row, isHovered, handleMouseEnter, handleMouseLeave, handleRowClick }) => (
      <TableCell key="index" align="left" sx={{ width: 20, maxWidth: 20 }}>
        {isHovered === row.index ? (
          <IconButton onClick={(event) => handleRowClick(event, row.track)}>
            <PlayArrowIcon />
          </IconButton>
        ) : (
          <Typography>{row.index + 1}</Typography>
        )}
      </TableCell>
    ),
  },
  {
    field: 'name',
    headerName: 'Name',
    valueGetter: ({ row }) => row.name,
    renderCell: (params) => (
      <TableCell>
        <Stack direction="row" alignItems="center">
          {/* <Ratio ratio={1} sx={{ height: '100%', backgroundColor: 'red' }}> */}
          <IPFSImage
            src={params.row.album?.thumbnail} // Replace with the actual URL of the album art image
            alt={params.row.name}
            sx={{
              marginRight: '8px',
              minWidth: '40px',
              maxWidth: '40px',
              height: '40px',
              borderRadius: '8px',
            }}
          />
          {/* </Ratio> */}
          {columnText(params.row.name)}
        </Stack>
      </TableCell>
    ),
  },
  {
    field: 'artist',
    headerName: 'Artist',
    description: 'This column has a value getter and is not sortable.',
    width: 160,
    valueGetter: ({ row }) => row.artist?.name,
  },
  {
    field: 'album',
    headerName: 'Album',
    width: 150,
    valueGetter: ({ row }) => row.album?.title,
  },
  {
    field: 'genre',
    headerName: 'Genre',
    width: 150,
    valueGetter: ({ row }) => row.album?.genre ?? row.genre,
  },
  {
    field: 'release',
    headerName: 'Release Date',
    width: 150,
    sortable: true, // This ensures the column can be sorted
    valueGetter: ({ row }) => row.album?.releaseDate, // Return the date for sorting
    renderCell: (params) => {
      const release = params.row.album?.releaseDate;
      return <TableCell>{release ? release.toDate().toDateString() : ''}</TableCell>;
    },
  },
  {
    field: 'action',
    headerName: '',
    renderCell: ({ row, isHovered, handleMouseEnter, handleMouseLeave, handleRowClick }) => (
      <TableCell width={40} align="right">
        <div width={40}>
          {isHovered === row.index && (
            <IconButton onClick={(event) => handleRowClick({ ...event, button: 2 }, row.track)}>
              <MoreVertIcon />
            </IconButton>
          )}
        </div>
      </TableCell>
    ),
  },
];

function TrackList({ data = [], playlist, onReorder }) {
  const { player } = useLibrary();
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [tracks, setTracks] = useState(data);

  const sortable = !!onReorder;

  useEffect(() => {
    setTracks(data);

    // eslint-disable-next-line
  }, [data]);

  const handleRowClick = (event, track) => {
    if (event.button === 2) {
      event?.preventDefault?.();
      event?.stopPropagation?.();

      setContextMenu({
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
      });
      setSelectedTrack(track);
    } else {
      setSelectedTrack(track);
      player.setTrack(track, tracks);
    }
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const isSelected = (track) =>
    player.track ? player.track?.id === track?.id : selectedTrack?.id === track?.id;

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = arrayMove(tracks, result.source.index, result.destination.index);
    setTracks(items);
    onReorder?.(items);
  };

  const width = useWidth();
  const isSmall = width === 'xs' || width === 'sm';

  const showFields = isSmall ? ['index', 'name', 'artist', 'action'] : columns.map((e) => e.field);
  const visibleColumns = columns.filter((e) => showFields.includes(e.field));

  const tableBody = React.useMemo(
    () => (
      <TableBody component={DroppableComponent(onDragEnd)}>
        {tracks?.map((track, index) => (
          <MemoizedTableRow
            hover
            key={track?.id}
            track={track}
            index={index}
            handleRowClick={handleRowClick}
            columns={visibleColumns}
            sortable={sortable}
            component={sortable ? DraggableComponent(track.id, index) : null}
            onClick={(event) => handleRowClick(event, track)}
            onContextMenu={(event) => handleRowClick(event, track)}
            selected={isSelected(track)}
          />
        ))}
      </TableBody>
    ),
    // eslint-disable-next-line
    [tracks, selectedTrack, player.track]
  );

  return (
    <HoveredProvider>
      <Box sx={{ width: '100%' }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {visibleColumns.map((column, index) => (
                  <TableCell key={column.field} width={column.width}>
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
        <TrackContextMenu
          track={selectedTrack}
          playlist={playlist}
          open={Boolean(contextMenu)}
          onClose={handleClose}
          anchorReference="anchorPosition"
          contextMenu={contextMenu}
          anchorPosition={
            contextMenu ? { top: contextMenu.mouseY, left: contextMenu.mouseX + 200 } : undefined
          }
        />
      </Box>
    </HoveredProvider>
  );
}

const MemoizedTableRow = React.memo(
  ({ track, index, handleRowClick, selected, columns: cols, sortable }) => {
    const { hoveredIndex, setHoveredIndex } = useContext(HoveredContext);
    const isThisRowHovered = hoveredIndex === index;

    const memoizedDraggableComponent = React.useMemo(
      () => (sortable ? DraggableComponent(track.id, index) : null),
      [track.id, index, sortable]
    );

    return (
      <TableRow
        hover
        component={memoizedDraggableComponent}
        onClick={(event) => handleRowClick(event, track)}
        onContextMenu={(event) => handleRowClick(event, track)}
        selected={selected}
        key={track?.id}
        onMouseEnter={() => setHoveredIndex(index)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
        <PlayArrowCell
          isHovered={isThisRowHovered}
          handleRowClick={handleRowClick}
          track={track}
          index={index}
        />

        {cols
          .filter((column) => column.field !== 'index' && column.field !== 'action')
          .map((column) => (
            <MemoizedTableCell
              key={column.field}
              column={column}
              row={track}
              hovered={isThisRowHovered}
              handleRowClick={handleRowClick}
            />
          ))}

        <MoreVertCell isHovered={isThisRowHovered} handleRowClick={handleRowClick} track={track} />
      </TableRow>
    );
  }
);

const PlayArrowCell = ({ index, isHovered, handleRowClick, track }) => (
  <TableCell key={track?.id ?? index} align="left" sx={{ width: 40, maxWidth: 40 }}>
    {isHovered ? (
      <IconButton
        sx={{ padding: 0 }}
        onClick={(event) => handleRowClick(event, track)}
        size="small"
        disabled={!isHovered}
      >
        <PlayArrowIcon fontSize="small" />
      </IconButton>
    ) : (
      <Typography textAlign="start" sx={{ width: 40, maxWidth: 40 }}>
        {index + 1}
      </Typography>
    )}
  </TableCell>
);

const MoreVertCell = ({ isHovered, handleRowClick, track }) => (
  <TableCell key="action" align="right">
    <IconButton
      onClick={(event) => {
        event.button = 2;
        handleRowClick(event, track);
      }}
      size="small"
      disabled={!isHovered}
    >
      <MoreVertIcon fontSize="small" />
    </IconButton>
  </TableCell>
);

const MemoizedTableCell = React.memo(
  ({ column, row, hovered, onClick }) =>
    column.renderCell ? (
      column.renderCell({ row, hovered, onClick })
    ) : (
      <TableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {columnText(column.valueGetter?.({ row }) ?? '')}
      </TableCell>
    ),

  (prevProps, nextProps) =>
    prevProps.row !== nextProps.row ||
    prevProps.hovered !== nextProps.hovered ||
    prevProps.onClick !== nextProps.onClick
);

export default React.memo(TrackList);
