import { useState, useEffect } from 'react';
import { db } from 'src/utils/firebase';
import * as SongService from './SongService';

const usePlaylist = (playlistId) => {
  const [playlist, setPlaylist] = useState(null);
  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    const unsubscribe = db
      .collection('playlists')
      .doc(playlistId)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          const playlistData = snapshot.data();

          // Extract trackIds from playlistData
          const { trackRefs, ...playlistWithoutTracks } = playlistData;

          // Set playlist data without tracks
          setPlaylist({ id: snapshot.id, ...playlistWithoutTracks });

          // Resolve and set track data
          SongService.getTracks(trackRefs).then((data) => setTracks(data));
        } else {
          setPlaylist(null);
          setTracks([]);
        }
      });

    return () => unsubscribe();
  }, [playlistId]);

  return { playlist, tracks };
};

export default usePlaylist;
